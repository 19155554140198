import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgBolognese from '../../assets/img/food/Bolognese.png';
import imgCarbonara from '../../assets/img/food/Carbonara.png';
import imgGenovese from '../../assets/img/food/Genovese.png';
import imgSpaghettiVongoleBianco from '../../assets/img/food/Spaghetti_alle_Vongole_in_Bianco.png';
import imgThreeStarPescatora from '../../assets/img/food/Three-Star_Pescatora.png';

const Pasta = props => {
    let navigate = useNavigate();

    return(
        <div id="pasta" className="content-wrapper">
            <Helmet>
                <title>Pasta | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgBolognese} />
                <meta property="og:url" content={imgBolognese} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#F8CE5F", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Pasta</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pasta-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Bolognese</td>
                                <td><img alt="Bolognese" className="img-food-content" src={imgBolognese} /></td>
                                <td>Recovers a lot of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Spaghetti Carbonara</td>
                                <td><img alt="Spaghetti Carbonara" className="img-food-content" src={imgCarbonara} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Genovese</td>
                                <td><img alt="Genovese" className="img-food-content" src={imgGenovese} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Clam Spaghetti</td>
                                <td><img alt="Clam Spaghetti" className="img-food-content" src={imgSpaghettiVongoleBianco} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>3-Star Pescatore</td>
                                <td><img alt="3-Star Pescatore" className="img-food-content" src={imgThreeStarPescatora} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Pasta;