import React from 'react';
import { Helmet } from 'react-helmet-async';

import '../../assets/css/Home.css';
import iconLogo from '../../assets/img/icon-logo.webp';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import iconFacebook from '../../assets/img/social/icon-facebook.png';
import iconX from '../../assets/img/social/icon-x.png';
import iconInstagram from '../../assets/img/social/icon-instagram.png';
import iconThreads from '../../assets/img/social/icon-threads.png';
import ogImage from '../../assets/img/og-image.jpg';

const Home = props => {
    return(
        <div id="home" className="home-content-wrapper content-wrapper">
            <Helmet>
                <title>YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:description" content="The site contains informations about all the yo-kais, their favorite foods, locations, items, evolutions, stats, etc." />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={ogImage} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#758EFF", }}>
                        <div className="header-title-wrapper">
                            <span className="menu-back"><img alt="logo" className="icon-back" src={iconLogo} /></span>
                            <h1 className="header-title">Home</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-content">
                <div className="container">
                    <div className="home">
                        <p>Yokaidex is a website with information about the games from Level-5: Yo-kai watch.</p>
                        <p>All the information gathered here is to be used as a resource to help our community have more info about the game.</p>
                        <p>All of it is the intellectual property of Level-5 Inc., Nintendo Co., Ltd., Bandai Co., Ltd., Koei Tecmo Holdings Co., Ltd. and is protected by various copyrights and trademarks.</p>
                        <p>The author believes that the use of this intellectual property for a fan reference is covered by fair use — the use is inherently educational, and the software would be severely impaired without the copyrighted material.</p>
                        <div className="follow-wrapper">
                            <p className="follow-title">Follow us on:</p>
                            <ul className="socials-wrapper">
                                <li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/yokaiinfodx/"><img title="Facebook" alt="social icon" width="35" heigh="35" className="icon-follow" src={iconFacebook} /></a></li>
                                <li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/yokaiinfodx/"><img title="Instagram" alt="social icon" width="35" heigh="35" className="icon-follow" src={iconInstagram} /></a></li>
                                <li><a rel="noreferrer" target="_blank" href="https://www.threads.net/@yokaiinfodx"><img title="Threads" alt="social icon" width="35" heigh="35" className="icon-follow" src={iconThreads} /></a></li>
                                <li><a rel="noreferrer" target="_blank" href="https://x.com/yokaiinfodx"><img title="X" alt="social icon" width="35" heigh="35" className="icon-follow" src={iconX} /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;