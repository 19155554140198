import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgPlumRiceBall from '../../assets/img/food/Plum_Rice_Ball.png';
import imgLeafRiceBall from '../../assets/img/food/Leaf_Rice_Ball.png';
import imgRoeRiceBall from '../../assets/img/food/Roe_Rice_Ball.png';
import imgShrimpRiceBall from '../../assets/img/food/Shrimp_Rice_Ball.png';
import imgDeluxeRiceBall from '../../assets/img/food/Deluxe_Rice_Ball.png';

const RiceBalls = props => {
    let navigate = useNavigate();

    return(
        <div id="rice-balls" className="content-wrapper">
            <Helmet>
                <title>Rice Balls | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgPlumRiceBall} />
                <meta property="og:url" content={imgPlumRiceBall} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#E7B48C", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Rice Balls</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rice-balls-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Plum Rice Ball</td>
                                <td><img alt="Plum Rice Ball" className="img-food-content" src={imgPlumRiceBall} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Leaf Rice Ball</td>
                                <td><img alt="Leaf Rice Ball" className="img-food-content" src={imgLeafRiceBall} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Roe Rice Ball</td>
                                <td><img alt="Roe Rice Ball" className="img-food-content" src={imgRoeRiceBall} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Shrimp Rice Ball</td>
                                <td><img alt="Shrimp Rice Ball" className="img-food-content" src={imgShrimpRiceBall} /></td>
                                <td>Recovers a lot of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Deluxe Rice Ball</td>
                                <td><img alt="Deluxe Rice Ball" className="img-food-content" src={imgDeluxeRiceBall} /></td>
                                <td>Recovers all HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default RiceBalls;