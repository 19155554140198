import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgChickenThigh from '../../assets/img/food/Chicken_Thigh.png';
import imgPorkCutlet from '../../assets/img/food/Porkchop-0.png';
import imgSlabBacon from '../../assets/img/food/Slab_Bacon.png';
import imgBeefTongue from '../../assets/img/food/Beef_Tongue.png';
import imgSirloinSteak from '../../assets/img/food/Sirloin_Steak.png';
import imgMarbledBeef from '../../assets/img/food/Marbled_Beef-0.png';
import imgChateaubriand from '../../assets/img/food/Chateaubriand-0.png';

const Meat = props => {
    let navigate = useNavigate();

    return(
        <div id="meat" className="content-wrapper">
            <Helmet>
                <title>Meat | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgChickenThigh} />
                <meta property="og:url" content={imgChickenThigh} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#EB6341", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Meat</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="meat-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Chicken Thigh</td>
                                <td><img alt="Chicken Thigh" className="img-food-content" src={imgChickenThigh} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Pork Cutlet</td>
                                <td><img alt="Pork Cutlet" className="img-food-content" src={imgPorkCutlet} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Slab Bacon</td>
                                <td><img alt="Slab Bacon" className="img-food-content" src={imgSlabBacon} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Beef Tongue</td>
                                <td><img alt="Beef Tongue" className="img-food-content" src={imgBeefTongue} /></td>
                                <td>Recovers a lot of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Sirloin Steak</td>
                                <td><img alt="Sirloin Steak" className="img-food-content" src={imgSirloinSteak} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Marbled Beef</td>
                                <td><img alt="Marbled Beef" className="img-food-content" src={imgMarbledBeef} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Chateaubriand</td>
                                <td><img alt="Chateaubriand" className="img-food-content" src={imgChateaubriand} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Meat;