import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgDonut from '../../assets/img/food/Donut.png';
import imgTwistyDonut from '../../assets/img/food/Twisty_Donut.png';
import imgBitChocDonut from '../../assets/img/food/Bit_o_Choc_Donut.png';
import imgCosmicDonut from '../../assets/img/food/Cosmic_Donut.png';

const Donuts = props => {
    let navigate = useNavigate();

    return(
        <div id="donuts" className="content-wrapper">
            <Helmet>
                <title>Donuts | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgDonut} />
                <meta property="og:url" content={imgDonut} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#C2A18A", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Donuts</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="donuts-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Donut</td>
                                <td><img alt="Donut" className="img-food-content" src={imgDonut} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Twisty Donut</td>
                                <td><img alt="Twisty Donut" className="img-food-content" src={imgTwistyDonut} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Chocolate Doughnut</td>
                                <td><img alt="Chocolate Doughnut" className="img-food-content" src={imgBitChocDonut} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Cosmic Donut</td>
                                <td><img alt="" className="img-food-content" src={imgCosmicDonut} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Donuts;