import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

import '../../assets/css/Contact.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import ogImage from '../../assets/img/og-image.jpg';

const Contact = props => {
    let navigate = useNavigate();

    const form = useRef();
    const [statusMessage, setStatusMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setIsLoading(true);
        emailjs.sendForm('service_vmv7yjq', 'template_9nyfu3t', form.current, 'zP1EUZrMc4D4hCO9Q')
            .then((result) => {
                console.log(result.text);
                setIsLoading(false);
                setStatusMessage("<p class='success-form-alert'>Thank you for your message. It has been sent.<p>");
                form.current.reset();
            }, (error) => {
                console.log(error.text);
                setIsLoading(false);
                setStatusMessage("<p class='failed-form-alert'>There was an error trying to send your message. Please try again later.<p>");
            });
    };

    return(
        <div id="contact" className="content-wrapper">
            <Helmet>
                <title>Contact Us | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:description" content="The site contains informations about all the yo-kais, their favorite foods, locations, items, evolutions, stats, etc." />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={ogImage} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#FFC648", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Contact Us</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-content">
                <div className="container">
                    <div className="contact-form-wrapper">
                        <form className="contact-form" ref={form} onSubmit={sendEmail}>
                            <label>Full name <span className="require">*</span></label>
                            <input type="text" name="user_name" placeholder="Enter your full name" required />
                            <label>Email <span className="require">*</span></label>
                            <input type="email" name="user_email" placeholder="Enter your email" required />
                            <label>Subject <span className="require">*</span></label>
                            <input name="subject" placeholder="Enter your subject" required />
                            <label>Message</label>
                            <textarea name="message" rows="5" placeholder="Enter your message" />
                            <button type="submit" className="btn-contact-form">{isLoading ? "Sending..." : "Send Email 🚀"}</button>
                        </form>
                        <div dangerouslySetInnerHTML={{ __html: statusMessage }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;