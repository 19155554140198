import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgMilk from '../../assets/img/food/Milk-0.png';
import imgCoffeeMilk from '../../assets/img/food/Coffee_Milk.png';
import imgFruitMilk from '../../assets/img/food/Fruit_Milk.png';
import imgAmazingMilk from '../../assets/img/food/Amazing_Milk-0.png';

const Milk = props => {
    let navigate = useNavigate();

    return(
        <div id="milk" className="content-wrapper">
            <Helmet>
                <title>Milk | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgMilk} />
                <meta property="og:url" content={imgMilk} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#d5b8a5" }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Milk</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="milk-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Milk</td>
                                <td><img alt="Milk" className="img-food-content" src={imgMilk} /></td>
                                <td>Adds a bit to the Soul Meter</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Coffee Milk</td>
                                <td><img alt="Coffee Milk" className="img-food-content" src={imgCoffeeMilk} /></td>
                                <td>Adds some to the Soul Meter</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Fruit Milk</td>
                                <td><img alt="Fruit Milk" className="img-food-content" src={imgFruitMilk} /></td>
                                <td>Adds some to Soul Meter</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Amazing Milk</td>
                                <td><img alt="Amazing Milk" className="img-food-content" src={imgAmazingMilk} /></td>
                                <td>Adds a ton to the Soul Meter</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Milk;