import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgSukiyakiLunchbox from '../../assets/img/food/Sukiyaki_(1).png';
import imgChickenSukiyaki from '../../assets/img/food/Chicken_Sukiyaki.png';
import imgBeefSukiyaki from '../../assets/img/food/Sukiyaki_(2).png';
import imgSpecialMarbledSukiyaki from '../../assets/img/food/Sukiyaki_(3).png';
import imgUltimateSukiyaki from '../../assets/img/food/Ultimate_Sukiyaki-0.png';
import imgSushiSukiyakiFeast from '../../assets/img/food/Sushi-Sukiyaki_of_the_Imperial_Kitchen.png';
import imgTempuraSukiyakiFeast from '../../assets/img/food/Tempura-Sukiyaki_of_the_Imperial_Kitchen.png';

const Sukiyaki = props => {
    let navigate = useNavigate();

    return(
        <div id="sukiyaki" className="content-wrapper">
            <Helmet>
                <title>Sukiyaki | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgSukiyakiLunchbox} />
                <meta property="og:url" content={imgSukiyakiLunchbox} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#E6835C", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Sukiyaki</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sukiyaki-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sukiyaki Lunchbox</td>
                                <td><img alt="Sukiyaki Lunchbox" className="img-food-content" src={imgSukiyakiLunchbox} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Chicken Sukiyaki</td>
                                <td><img alt="Chicken Sukiyaki" className="img-food-content" src={imgChickenSukiyaki} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Beef Sukiyaki</td>
                                <td><img alt="Beef Sukiyaki" className="img-food-content" src={imgBeefSukiyaki} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Special Marbled Sukiyak</td>
                                <td><img alt="Special Marbled Sukiyak" className="img-food-content" src={imgSpecialMarbledSukiyaki} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Sukiyaki Yo-kai Special</td>
                                <td><img alt="Sukiyaki Yo-kai Special" className="img-food-content" src={imgUltimateSukiyaki} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Sushi Sukiyaki Feast</td>
                                <td><img alt="Sushi Sukiyaki Feast" className="img-food-content" src={imgSushiSukiyakiFeast} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Tempura Sukiyaki Feast</td>
                                <td><img alt="Tempura Sukiyaki Feast" className="img-food-content" src={imgTempuraSukiyakiFeast} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Sukiyaki;