import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Changelog.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import ogImage from '../../assets/img/og-image.jpg';

const Changelog = props => {
    let navigate = useNavigate();

    return(
        <div id="attitudes" className="content-wrapper">
            <Helmet>
                <title>Changelog | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:description" content="The site contains informations about all the yo-kais, their favorite foods, locations, items, evolutions, stats, etc." />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={ogImage} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#AA90B4", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Changelog</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="changelog-content">
                <div className="container">
                    <div className="table-responsive">
                        <table cellSpacing={0} className="table-changelog">
                            <tbody>
                                <tr>
                                    <td className="changelog-content-title">v0.1.8 release</td>
                                    <td>
                                        <p>- Now you can filter Yo-kais in Medallium by attribute.</p>
                                        <p>- Clear this website cache to update newest content.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="changelog-content-title">v0.1.7 release</td>
                                    <td>
                                        <p>- Now you can filter Yo-kais in Medallium by rank and trible.</p>
                                        <p>- Clear this website cache to update newest content.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="changelog-content-title">v0.1.6 release</td>
                                    <td>
                                        <p>- Fix some bugs.</p>
                                        <p>- Clear this website cache to update newest content.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="changelog-content-title">v0.1.5 release</td>
                                    <td>
                                        <p>- Update QR Code.</p>
                                        <p>- You can use ← / → on your keyboard or scroll up / down on your middle button mouse to move prev / next slide in QR Code slide.</p>
                                        <p>- Clear this website cache to update newest content.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="changelog-content-title">v0.1.4 release</td>
                                    <td>
                                        <p>- Update Yo-kais hight quality image in medallium and yokai information.</p>
                                        <p>- Fix some bugs.</p>
                                        <p>- Clear this website cache to update newest content.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="changelog-content-title">v0.1.3 release</td>
                                    <td>
                                        <p>- Update Food.</p>
                                        <p>- Clear this website cache to update newest content.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="changelog-content-title">v0.1.2 release</td>
                                    <td>
                                        <p>- Update Password.</p>
                                        <p>- Clear this website cache to update newest content.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="changelog-content-title">v0.1.1 release</td>
                                    <td>
                                        <p>- Fix some bugs.</p>
                                        <p>- Clear this website cache to update newest content.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="changelog-content-title">v0.1.0 release</td>
                                    <td>
                                        <p>- Medallium.</p>
                                        <p>- Baffle Board.</p>
                                        <p>- Attitude.</p>
                                        <p>- RARE Special Soul.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Changelog;