import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgPlainHotDog from '../../assets/img/food/Plain_Hot_Dog.png';
import imgBurgerDog from '../../assets/img/food/Burger_Dog.png';
import imgHotChiliDog from '../../assets/img/food/Hot_Chili_Dog.png';
import imgPremiumSpecialHotDog from '../../assets/img/food/Premium_Special_Hot_Dog.png';

const HotDogs = props => {
    let navigate = useNavigate();

    return(
        <div id="hot-dogs" className="content-wrapper">
            <Helmet>
                <title>Hot Dogs | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgPlainHotDog} />
                <meta property="og:url" content={imgPlainHotDog} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#EEC75C", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Hot Dogs</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hot-dogs-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Plain Hotdog</td>
                                <td><img alt="Plain Hotdog" className="img-food-content" src={imgPlainHotDog} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Ground Beef Dog</td>
                                <td><img alt="Ground Beef Dog" className="img-food-content" src={imgBurgerDog} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Hot Chili Dog</td>
                                <td><img alt="Hot Chili Dog" className="img-food-content" src={imgHotChiliDog} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Special Hot Dog</td>
                                <td><img alt="Special Hot Dog" className="img-food-content" src={imgPremiumSpecialHotDog} /></td>
                                <td>Recovers a lot of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default HotDogs;