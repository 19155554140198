import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import '../../assets/css/BaffleBoard.css';
import Baffleboards from '../../assets/jsons/baffleboarddb.json';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import ogImage from '../../assets/img/og-image.jpg';

const BaffleBoard = props => {
    let navigate = useNavigate();

    return(
        <div id="baffle-board" className="content-wrapper">
            <Helmet>
                <title>Baffle Board | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:description" content="The site contains informations about all the yo-kais, their favorite foods, locations, items, evolutions, stats, etc." />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={ogImage} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#6DAA73", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Baffle Board</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="baffle-board-content">
                <div className="container">
                    <div className="baffle-board">
                        <h2 className="baffle-board-content-title">Yo-kai Watch 3</h2>
                        <div className="table-responsive">
                            <table cellSpacing={0} className="table-baffle-board table-baffle-board-1">
                                <thead>
                                    <tr>
                                        <th>Location</th>
                                        <th>Clues</th>
                                        <th>Solution</th>
                                        <th>Effect</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    (
                                        Baffleboards.baffleboards3.map((bf3) => {
                                            return (
                                                <tr key={bf3.solution}>
                                                    <td>{bf3.location}</td>
                                                    <td>
                                                        <p>1. {bf3.clues[0]}</p>
                                                        <p>2. {bf3.clues[1]}</p>
                                                        <p>3. {bf3.clues[2]}</p>
                                                    </td>
                                                    <td className="baffle-board-solution">
                                                        <Link to={`/yokai-information/${bf3.slug}`}>
                                                            {bf3.solution}
                                                        </Link>
                                                    </td>
                                                    <td>{bf3.effect}</td>
                                                </tr>
                                            )
                                        })
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="baffle-board">
                        <h2 className="baffle-board-content-title">Yo-kai Watch 2</h2>
                        <div className="table-responsive">
                            <table cellSpacing={0} className="table-baffle-board table-baffle-board-2">
                                <thead>
                                    <tr>
                                        <th>Location</th>
                                        <th>Clues</th>
                                        <th>Solution</th>
                                        <th>Effect</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (
                                            Baffleboards.baffleboards2.map((bf2) => {
                                                return (
                                                    <tr key={bf2.solution}>
                                                        <td>{bf2.location}</td>
                                                        <td>
                                                            <p>1. {bf2.clues[0]}</p>
                                                            <p>2. {bf2.clues[1]}</p>
                                                            <p>3. {bf2.clues[2]}</p>
                                                        </td>
                                                        <td className="baffle-board-solution">
                                                            <Link to={`/yokai-information/${bf2.slug}`}>
                                                                {bf2.solution}
                                                            </Link>
                                                        </td>
                                                        <td>{bf2.effect}</td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="baffle-board">
                        <h2 className="baffle-board-content-title">Gate of Whimsy: Quiz Room Baffle Boards</h2>
                        <div className="table-responsive">
                            <table cellSpacing={0} className="table-baffle-board table-baffle-board-3">
                                <thead>
                                    <tr>
                                        <th>Clues</th>
                                        <th>Solution</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (
                                            Baffleboards.gateofwhimsy.map((gfw) => {
                                                return (
                                                    <tr key={gfw.solution}>
                                                        <td>
                                                            <p>1. {gfw.clues[0]}</p>
                                                            <p>2. {gfw.clues[1]}</p>
                                                            <p>3. {gfw.clues[2]}</p>
                                                        </td>
                                                        <td className="baffle-board-solution">
                                                            <Link to={`/yokai-information/${gfw.slug}`}>
                                                                {gfw.solution}
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BaffleBoard;