import Home from '../pages/Home';
import Medallium from '../pages/Medallium';
import YokaiDetail from '../pages/YokaiDetail';
import QRCode from '../pages/QRCode';
import QRCodeDetail from '../pages/QRCodeDetail';
import QRCodeList from '../pages/QRCodeList';
import BaffleBoard from '../pages/BaffleBoard';
import Attitudes from '../pages/Attitudes';
import Password from '../pages/Password';
import Password1 from '../pages/Password1';
import Password1ENUS from '../pages/Password1/Password1ENUS.js';
import Password1ESUS from '../pages/Password1/Password1ESUS.js';
import Password1FRUS from '../pages/Password1/Password1FRUS.js';
import Password1ENEU from '../pages/Password1/Password1ENEU.js';
import Password1ESEU from '../pages/Password1/Password1ESEU.js';
import Password1FREU from '../pages/Password1/Password1FREU.js';
import Password1DE from '../pages/Password1/Password1DE.js';
import Password1IT from '../pages/Password1/Password1IT.js';
import Password1KO from '../pages/Password1/Password1KO.js';
import Password1JA from '../pages/Password1/Password1JA.js';
import Password2 from '../pages/Password2';
import Password2ENUS from '../pages/Password2/Password2ENUS.js';
import Password2ES from '../pages/Password2/Password2ES.js';
import Password2FR from '../pages/Password2/Password2FR.js';
import Password2ENEU from '../pages/Password2/Password2ENEU.js';
import Password2DE from '../pages/Password2/Password2DE.js';
import Password2IT from '../pages/Password2/Password2IT.js';
import Password2NL from '../pages/Password2/Password2NL.js';
import Password2RU from '../pages/Password2/Password2RU.js';
import Password2KO from '../pages/Password2/Password2KO.js';
import Password2JA from '../pages/Password2/Password2JA.js';
import Password3 from '../pages/Password3';
import Password3EN from '../pages/Password3/Password3EN.js';
import Password3ES from '../pages/Password3/Password3ES.js';
import Password3FR from '../pages/Password3/Password3FR.js';
import Password3DE from '../pages/Password3/Password3DE.js';
import Password3IT from '../pages/Password3/Password3IT.js';
import RARESpecialSoul from '../pages/RARESpecialSoul';
import Food from '../pages/Food';
import Bread from '../pages/Food/Bread.js';
import Candy from '../pages/Food/Candy.js';
import Curry from '../pages/Food/Curry.js';
import ChineseFood from '../pages/Food/ChineseFood.js';
import Chocobars from '../pages/Food/Chocobars.js';
import Donuts from '../pages/Food/Donuts.js';
import Hamburgers from '../pages/Food/Hamburgers.js';
import HotDogs from '../pages/Food/HotDogs.js';
import IceCream from '../pages/Food/IceCream.js';
import Juice from '../pages/Food/Juice.js';
import Meat from '../pages/Food/Meat.js';
import MegaTasty from '../pages/Food/MegaTasty.js';
import Milk from '../pages/Food/Milk.js';
import Oden from '../pages/Food/Oden.js';
import Pasta from '../pages/Food/Pasta.js';
import Pizza from '../pages/Food/Pizza.js';
import Ramen from '../pages/Food/Ramen.js';
import RiceBalls from '../pages/Food/RiceBalls.js';
import Seafood from '../pages/Food/Seafood.js';
import Snacks from '../pages/Food/Snacks.js';
import Soba from '../pages/Food/Soba.js';
import Sukiyaki from '../pages/Food/Sukiyaki.js';
import Sushi from '../pages/Food/Sushi.js';
import Sweets from '../pages/Food/Sweets.js';
import Tempura from '../pages/Food/Tempura.js';
import Veggies from '../pages/Food/Veggies.js';
import Contact from '../pages/Contact';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Changelog from '../pages/Changelog';
import NotFound from "../pages/NotFound";

const publicRoutes = [
    { path: '/', component: Home },
    { path: '/medallium', component: Medallium },
    { path: '/yokai-information/:slug', component: YokaiDetail },
    { path: '/qr-code', component: QRCode },
    { path: '/qr-code/:slug', component: QRCodeList },
    { path: '/qr-code/:slug/:slug', component: QRCodeDetail },
    { path: '/baffle-board', component: BaffleBoard },
    { path: '/attitudes', component: Attitudes },
    { path: '/password', component: Password },
    { path: '/password-1', component: Password1 },
    { path: '/password-1/en-us', component: Password1ENUS },
    { path: '/password-1/es-us', component: Password1ESUS },
    { path: '/password-1/fr-us', component: Password1FRUS },
    { path: '/password-1/en-eu', component: Password1ENEU },
    { path: '/password-1/es-eu', component: Password1ESEU },
    { path: '/password-1/fr-eu', component: Password1FREU },
    { path: '/password-1/de', component: Password1DE },
    { path: '/password-1/it', component: Password1IT },
    { path: '/password-1/ko', component: Password1KO },
    { path: '/password-1/ja', component: Password1JA },
    { path: '/password-2', component: Password2 },
    { path: '/password-2/en-us', component: Password2ENUS },
    { path: '/password-2/es', component: Password2ES },
    { path: '/password-2/fr', component: Password2FR },
    { path: '/password-2/en-eu', component: Password2ENEU },
    { path: '/password-2/de', component: Password2DE },
    { path: '/password-2/it', component: Password2IT },
    { path: '/password-2/nl', component: Password2NL },
    { path: '/password-2/ru', component: Password2RU },
    { path: '/password-2/ko', component: Password2KO },
    { path: '/password-2/ja', component: Password2JA },
    { path: '/password-3', component: Password3 },
    { path: '/password-3/en', component: Password3EN },
    { path: '/password-3/es', component: Password3ES },
    { path: '/password-3/fr', component: Password3FR },
    { path: '/password-3/de', component: Password3DE },
    { path: '/password-3/it', component: Password3IT },
    { path: '/rare-special-soul', component: RARESpecialSoul },
    { path: '/food', component: Food },
    { path: '/food/bread', component: Bread },
    { path: '/food/candy', component: Candy },
    { path: '/food/curry', component: Curry },
    { path: '/food/chinese-food', component: ChineseFood },
    { path: '/food/chocobars', component: Chocobars },
    { path: '/food/donuts', component: Donuts },
    { path: '/food/hamburgers', component: Hamburgers },
    { path: '/food/hot-dogs', component: HotDogs },
    { path: '/food/ice-cream', component: IceCream },
    { path: '/food/juice', component: Juice },
    { path: '/food/meat', component: Meat },
    { path: '/food/mega-tasty', component: MegaTasty },
    { path: '/food/milk', component: Milk },
    { path: '/food/oden', component: Oden },
    { path: '/food/pasta', component: Pasta },
    { path: '/food/pizza', component: Pizza },
    { path: '/food/ramen', component: Ramen },
    { path: '/food/rice-balls', component: RiceBalls },
    { path: '/food/seafood', component: Seafood },
    { path: '/food/snacks', component: Snacks },
    { path: '/food/soba', component: Soba },
    { path: '/food/sukiyaki', component: Sukiyaki },
    { path: '/food/sushi', component: Sushi },
    { path: '/food/sweets', component: Sweets },
    { path: '/food/tempura', component: Tempura },
    { path: '/food/veggies', component: Veggies },
    { path: '/privacy-policy', component: PrivacyPolicy },
    { path: '/contact', component: Contact },
    { path: '/changelog', component: Changelog },
    { path: '*', component: NotFound }
];

export { publicRoutes };