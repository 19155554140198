import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgIceCream from '../../assets/img/food/Ice_Cream.png';
import imgBerryBerryIceCream from '../../assets/img/food/Berry_Berry_Ice_Cream.png';
import imgRainbowIceCream from '../../assets/img/food/Rainbow_Ice_Cream.png';
import imgSpecialSundae from '../../assets/img/food/Special_Sundae.png';

const IceCream = props => {
    let navigate = useNavigate();

    return(
        <div id="ice-cream" className="content-wrapper">
            <Helmet>
                <title>Ice Cream | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgIceCream} />
                <meta property="og:url" content={imgIceCream} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#FEA543", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Ice Cream</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ice-cream-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Ice Cream</td>
                                <td><img alt="Ice Cream" className="img-food-content" src={imgIceCream} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Berry Ice Cream</td>
                                <td><img alt="Berry Ice Cream" className="img-food-content" src={imgBerryBerryIceCream} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Rainbow Soft Serve</td>
                                <td><img alt="Rainbow Soft Serve" className="img-food-content" src={imgRainbowIceCream} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Special ☆ Sundae</td>
                                <td><img alt="Special ☆ Sundae" className="img-food-content" src={imgSpecialSundae} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default IceCream;