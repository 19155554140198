import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgDriedMackerel from '../../assets/img/food/Dried_Mackerel.png';
import imgYellowtail from '../../assets/img/food/Yellowtail.png';
import imgMussels from '../../assets/img/food/Mussels-0.png';
import imgLobster from '../../assets/img/food/Lobster-0.png';
import imgFreshUrchin from '../../assets/img/food/Fresh_Urchin.png';
import imgKingSalmon from '../../assets/img/food/King_Salmon-0.png';
import imgChoiceTuna from '../../assets/img/food/Choice_Tuna.png';

const Seafood = props => {
    let navigate = useNavigate();

    return(
        <div id="seafood" className="content-wrapper">
            <Helmet>
                <title>Seafood | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgDriedMackerel} />
                <meta property="og:url" content={imgDriedMackerel} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#EDA38D", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Seafood</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="seafood-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Dried Mackerel</td>
                                <td><img alt="Dried Mackerel" className="img-food-content" src={imgDriedMackerel} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Yellowtail</td>
                                <td><img alt="Yellowtail" className="img-food-content" src={imgYellowtail} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Mussels</td>
                                <td><img alt="Mussels" className="img-food-content" src={imgMussels} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Lobster</td>
                                <td><img alt="Lobster" className="img-food-content" src={imgLobster} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Fresh Urchin</td>
                                <td><img alt="Fresh Urchin" className="img-food-content" src={imgFreshUrchin} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>King Salmon</td>
                                <td><img alt="King Salmon" className="img-food-content" src={imgKingSalmon} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Choice Tuna</td>
                                <td><img alt="Choice Tuna" className="img-food-content" src={imgChoiceTuna} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Seafood;