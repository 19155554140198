import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Mousewheel, Pagination } from 'swiper/modules';

import 'swiper/css';
import '../../assets/css/QRCode.css';
import qrcodeslide from '../../assets/jsons/qrslide.json';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';

const QRCodeDetail = props => {
    const {slug} = useParams();
    let navigate = useNavigate();

    for (var i = 0; i < 5; i++) {
        return(
            <div id="qr-code-slide" className="content-wrapper">
                {
                    // eslint-disable-next-line
                    qrcodeslide.filter((qr) => slug === qr.slug).map((qr) => (
                        <Helmet key={qr.key}>
                            <title>{qr.name} | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                            <meta property="og:image" content={qr.icon} />
                            <meta property="og:url" content={qr.slug}></meta>
                        </Helmet>
                    ))
                }
                <div className="header-wrapper">
                    <div className="container">
                        {
                            // eslint-disable-next-line
                            qrcodeslide.filter((qr) => slug === qr.slug).map((qr) => (
                                <div key={qr.key} className="header-content" style={{ backgroundColor: qr.bgcolor, }}>
                                    <div className="header-title-wrapper">
                                        <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                                        <h1 className="header-title">{qr.name}</h1>
                                        <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="qr-code-slide-content-wrapper">
                    <div className="container">
                        <div className="qr-code-slide-content">
                            {
                                qrcodeslide.filter((qr) => slug === qr.slug).map((qr) => (
                                    <Swiper
                                    key={qr.key}
                                    keyboard={{
                                        enabled: true
                                    }}
                                    mousewheel={{
                                        enabled: true
                                    }}
                                    modules={[Keyboard, Mousewheel, Pagination]}
                                    pagination={{ 
                                        clickable: true,
                                        type: 'bullets',
                                        bulletElement: 'span',
                                        bulletClass: 'slide-number',
                                        bulletActiveClass: 'slide-number-active',
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + '">' + (index + 1) + '</span>';
                                        }
                                    }}
                                    >
                                        {(qr.slide[0]) !== "" &&
                                            <SwiperSlide><img alt="qr code" className="img-qr-slide" src={qr.slide[0]} /></SwiperSlide>
                                        }
                                        {(qr.slide[1]) !== "" &&
                                            <SwiperSlide><img alt="qr code" className="img-qr-slide" src={qr.slide[1]} /></SwiperSlide>
                                        }
                                        {(qr.slide[2]) !== "" &&
                                            <SwiperSlide><img alt="qr code" className="img-qr-slide" src={qr.slide[2]} /></SwiperSlide>
                                        }
                                        {(qr.slide[3]) !== "" &&
                                            <SwiperSlide><img alt="qr code" className="img-qr-slide" src={qr.slide[3]} /></SwiperSlide>
                                        }
                                        {(qr.slide[4]) !== "" &&
                                            <SwiperSlide><img alt="qr code" className="img-qr-slide" src={qr.slide[4]} /></SwiperSlide>
                                        }
                                        {(qr.slide[5]) !== "" &&
                                            <SwiperSlide><img alt="qr code" className="img-qr-slide" src={qr.slide[5]} /></SwiperSlide>
                                        }
                                        {(qr.slide[6]) !== "" &&
                                            <SwiperSlide><img alt="qr code" className="img-qr-slide" src={qr.slide[6]} /></SwiperSlide>
                                        }
                                        {(qr.slide[7]) !== "" &&
                                            <SwiperSlide><img alt="qr code" className="img-qr-slide" src={qr.slide[7]} /></SwiperSlide>
                                        }
                                        {(qr.slide[8]) !== "" &&
                                            <SwiperSlide><img alt="qr code" className="img-qr-slide" src={qr.slide[8]} /></SwiperSlide>
                                        }
                                        {(qr.slide[9]) !== "" &&
                                            <SwiperSlide><img alt="qr code" className="img-qr-slide" src={qr.slide[9]} /></SwiperSlide>
                                        }
                                    </Swiper>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default QRCodeDetail;