import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../../assets/css/Navbar.css';

const Navbar = props => {
    let location = useLocation();
    const navClass = location.pathname === "/medallium" ? "medallium-navigation" : "site-navigation";

    return(
        <nav id="navbar" className={navClass}>
            <div className="container">
                <ul className="navbar-content">
                    <li>
                        <Link
                        to="/"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/medallium"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/medallium" ||
                                location.pathname.split("/")[1] === "yokai-information"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            Medallium
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/qr-code"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/qr-code" ||
                                location.pathname.split("/")[1] === "qr-code"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            QR Code
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/baffle-board"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/baffle-board"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            Baffle Board
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/attitudes"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/attitudes"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            Attitudes
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/password"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/password" ||
                                location.pathname === "/password-1" ||
                                location.pathname.split("/")[1] === "password-1" ||
                                location.pathname === "/password-2" ||
                                location.pathname.split("/")[1] === "password-2" ||
                                location.pathname === "/password-3" ||
                                location.pathname.split("/")[1] === "password-3"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            Password
                        </Link>
                    </li>
                    <li>
                        <Link 
                        to="/rare-special-soul"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/rare-special-soul"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            RARE Special Soul
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/food"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/food" ||
                                location.pathname.split("/")[1] === "food"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            Food
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/contact"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/contact"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            Contact Us
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/privacy-policy"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/privacy-policy"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/changelog"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/changelog"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            Changelog
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar;