import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgRamenCup from '../../assets/img/food/Ramen_Cup.png';
import imgPorkRamen from '../../assets/img/food/Pork_Ramen.png';
import imgDeluxeRamen from '../../assets/img/food/Deluxe_Ramen.png';
import imgEverythingRamen from '../../assets/img/food/Everything_Ramen-0.png';

const Ramen = props => {
    let navigate = useNavigate();

    return(
        <div id="ramen" className="content-wrapper">
            <Helmet>
                <title>Ramen | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgRamenCup} />
                <meta property="og:url" content={imgRamenCup} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#B76517", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Ramen</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ramen-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Ramen Cup</td>
                                <td><img alt="Ramen Cup" className="img-food-content" src={imgRamenCup} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Pork Ramen</td>
                                <td><img alt="Pork Ramen" className="img-food-content" src={imgPorkRamen} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Deluxe Ramen</td>
                                <td><img alt="Deluxe Ramen" className="img-food-content" src={imgDeluxeRamen} /></td>
                                <td>Recovers a lot of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Everything Ramen</td>
                                <td><img alt="Everything Ramen" className="img-food-content" src={imgEverythingRamen} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Ramen;