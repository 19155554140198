import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgSandwhich from '../../assets/img/food/Sandwhich.png';
import imgCustardbread from '../../assets/img/food/Custardbreadhd.png';
import imgBlueberryBagel from '../../assets/img/food/Blueberry_Bagel.png';
import imgCurrybread from '../../assets/img/food/Currybreadhd.png';
import imgBaguette from '../../assets/img/food/Baguettehd.png';
import imgClubSandwich from '../../assets/img/food/Club_Sandwich.png';
import Blehgel from '../../assets/img/food/Blehgelhd.png';

const Bread = props => {
    let navigate = useNavigate();

    return(
        <div id="bread" className="content-wrapper">
            <Helmet>
                <title>Bread | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgSandwhich} />
                <meta property="og:url" content={imgSandwhich} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#BC7231", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Bread</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bread-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sandwich</td>
                                <td><img alt="Sandwich" className="img-food-content" src={imgSandwhich} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Custard Bread</td>
                                <td><img alt="Custard Bread" className="img-food-content" src={imgCustardbread} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Blueberry Bagel</td>
                                <td><img alt="Blueberry Bagel" className="img-food-content" src={imgBlueberryBagel} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a some</td>
                            </tr>
                            <tr>
                                <td>Curry Bread</td>
                                <td><img alt="Curry Bread" className="img-food-content" src={imgCurrybread} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Baguette</td>
                                <td><img alt="Baguette" className="img-food-content" src={imgBaguette} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Club Sandwich</td>
                                <td><img alt="Club Sandwich" className="img-food-content" src={imgClubSandwich} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Blehgel</td>
                                <td><img alt="Blehgel" className="img-food-content" src={Blehgel} /></td>
                                <td>Recovers a lot of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Bread;