import React from "react";
import { Link, useLocation } from 'react-router-dom';

import '../../assets/css/Sidebar.css';
import imgMenu from '../../assets/img/menu/img-menu.webp';
import iconHome from '../../assets/img/menu/icon-home.png';
import iconMedallium from '../../assets/img/menu/icon-medallium.png';
import iconQRCode from '../../assets/img/menu/icon-qr-code.png';
import iconBaffleBoard from '../../assets/img/menu/icon-baffle-board.png';
import iconAttitudes from '../../assets/img/menu/icon-attitudes.png';
import iconPassword from '../../assets/img/menu/icon-password.png';
import iconSoul from '../../assets/img/menu/icon-soul.png';
import iconFood from '../../assets/img/menu/icon-food.png';
import iconPolicy from '../../assets/img/menu/icon-policy.png';
import iconContact from '../../assets/img/menu/icon-contact.png';
import iconChangelog from '../../assets/img/menu/icon-changelog.png';

const Sidebar = props => {
    const sidebarClass = props.isOpen ? "sidebar open" : "sidebar";
    let location = useLocation();

    return (
        <div className={sidebarClass}>
            <div className="sidebar-content-wrapper">
                <img alt="menu" className="img-menu img-content" src={imgMenu} />
                <ul className="sidebar-content">
                    <li className="sidebar-home-link">
                        <Link
                        to="/"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            <img alt="icon" className="icon-menu" src={iconHome} /> Home
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/medallium"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/medallium" ||
                                location.pathname.split("/")[1] === "yokai-information"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            <img alt="icon" className="icon-menu" src={iconMedallium} /> Medallium
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/qr-code"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/qr-code" ||
                                location.pathname.split("/")[1] === "qr-code"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            <img alt="icon" className="icon-menu" src={iconQRCode} /> QR Code
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/baffle-board"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/baffle-board"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            <img alt="icon" className="icon-menu" src={iconBaffleBoard} /> Baffle Board
                        </Link>
                    </li>
                    <li className="sidebar-attitudes-link">
                        <Link
                        to="/attitudes"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/attitudes"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            <img alt="icon" className="icon-menu" src={iconAttitudes} /> Attitudes
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/password"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/password" ||
                                location.pathname === "/password-1" ||
                                location.pathname.split("/")[1] === "password-1" ||
                                location.pathname === "/password-2" ||
                                location.pathname.split("/")[1] === "password-2" ||
                                location.pathname === "/password-3" ||
                                location.pathname.split("/")[1] === "password-3"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            <img alt="icon" className="icon-menu" src={iconPassword} /> Password
                        </Link>
                    </li>
                    <li>
                        <Link 
                        to="/rare-special-soul"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/rare-special-soul"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            <img alt="icon" className="icon-menu" src={iconSoul} /> RARE Special Soul
                        </Link>
                    </li>
                    <li className="sidebar-food-link">
                        <Link
                        to="/food"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/food" ||
                                location.pathname.split("/")[1] === "food"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            <img alt="icon" className="icon-menu" src={iconFood} /> Food
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/contact"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/contact"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            <img alt="icon" className="icon-menu" src={iconContact} /> Contact Us
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/privacy-policy"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/privacy-policy"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            <img alt="icon" className="icon-menu" src={iconPolicy} /> Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link
                        to="/changelog"
                        onClick={props.toggleSidebar}
                        className={
                            (
                                location.pathname === "/changelog"
                            ) ? 'active nav-link' : 'nav-link'
                        }
                        >
                            <img alt="icon" className="icon-menu" src={iconChangelog} /> Changelog
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};
export default Sidebar;