import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgPotatoChips from '../../assets/img/food/Potato_Chips.png';
import imgCaramelPeanuts from '../../assets/img/food/Caramel_Peanuts-0.png';
import imgTastyNibbles from '../../assets/img/food/Tasty_Nibbles.png';
import imgCheesyCornChips from '../../assets/img/food/Cheesy_Corn_Chips.png';
import imgPeppyPopcorn from '../../assets/img/food/Buttered_Popcorn-0.png';
import imgSnowPeaSnacks from '../../assets/img/food/Snow_Pea_Snacks.png';

const Snacks = props => {
    let navigate = useNavigate();

    return(
        <div id="snacks" className="content-wrapper">
            <Helmet>
                <title>Snacks | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgPotatoChips} />
                <meta property="og:url" content={imgPotatoChips} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#97A642", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Snacks</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="snacks-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Potato Chips</td>
                                <td><img alt="Potato Chips" className="img-food-content" src={imgPotatoChips} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Caramel Peanuts</td>
                                <td><img alt="Caramel Peanuts" className="img-food-content" src={imgCaramelPeanuts} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Tasty Nibbles</td>
                                <td><img alt="Tasty Nibbles" className="img-food-content" src={imgTastyNibbles} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Cheesy Chips</td>
                                <td><img alt="Cheesy Chips" className="img-food-content" src={imgCheesyCornChips} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Peppy Popcorn</td>
                                <td><img alt="Peppy Popcorn" className="img-food-content" src={imgPeppyPopcorn} /></td>
                                <td>Recovers a lot of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Snow-Pea Snacks</td>
                                <td><img alt="Snow-Pea Snacks" className="img-food-content" src={imgSnowPeaSnacks} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Snacks;