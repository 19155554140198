import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga';

import { publicRoutes } from './routes/';
import Navbar from "./pages/Navbar";
import Sidebar from "./pages/Sidebar";
import './App.css';

const TRACKING_ID = "G-66J362J6R9";
ReactGA.initialize(TRACKING_ID);

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleViewSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const bgSidebarClass = sidebarOpen ? "bg-sidebar" : "bg-sidebar hidden";

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <HelmetProvider>
      <div className="App">
        <Sidebar 
          isOpen={sidebarOpen}
          toggleSidebar={handleViewSidebar}
        />
        <div className={bgSidebarClass} onClick={handleViewSidebar}></div>
        <Navbar />
        <Routes>
          {publicRoutes.map((route, index) => {
            const Page = route.component;
            return <Route key={index} path={route.path} element={<Page isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />} />;
          })}
        </Routes>
      </div>
    </HelmetProvider>
  );
  
}

export default App;
