import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import yokais from '../../assets/jsons/yokaidb.json';
import {
    FacebookIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
    FacebookShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";

import '../../assets/css/YokaiDetail.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';

const YokaiInfo = props => {
    const {slug} = useParams();
    let navigate = useNavigate();
    
    for (var i = 0; i < 5; i++) {
        return(
            <div id="yokai-detail" className="content-wrapper">
                {
                    // eslint-disable-next-line
                    yokais.filter((yokai) => slug === yokai.slug).map((yokai) => (
                        <Helmet key={yokai.key}>
                            <title>{yokai.name} | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                            <meta property="og:description" content={yokai.moreinfo[0]} />
                            <meta property="og:image" content={yokai.img[0].yokaiimg} />
                            <meta property="og:url" content={yokai.slug}></meta>
                        </Helmet>
                    ))
                }
                <div className="header-wrapper">
                    <div className="container">
                        {
                            yokais.filter((yokai) => slug === yokai.slug).map((yokai) => (
                                <div key={yokai.key} className="header-content" style={{ backgroundColor: yokai.bgcolor }}>
                                    <div className="header-title-wrapper">
                                        <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                                        <h1 className="header-title">{yokai.name}</h1>
                                        <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                {
                    // eslint-disable-next-line
                    yokais.filter((yokai) => slug === yokai.slug).map((yokai) => (
                        <div key={yokai.key} className="yokai-detail-wrapper">
                            <div className="container">
                                <div className="img-yokai-detail-wrapper" style={{ backgroundColor: yokai.bgcolor }}>
                                    <div className="bg-img-yokai-detail"></div>
                                    <img className="img-yokai-detail" alt={yokai.name} src={yokai.img[0].yokaiimg} />
                                    <span className="yokai-number">#{yokai.key}</span>
                                    <img alt={yokai.tribe} className="img-tribe-detail" src={yokai.img[i].tribeimg} />
                                    <img alt={yokai.attribute} className="img-attribute-detail" src={yokai.img[i].attrimg} />
                                    <img alt={yokai.rank[0]} className="img-rank-detail" src={yokai.img[i].rankimg} />
                                </div>
                                <div className="yokai-detail-food-wrapper">
                                    <h2 className="yokai-detail-title" style={{ backgroundColor: yokai.bgcolor }}>FAVORITE FOOD</h2>
                                    <div className="yokai-detail-food-content">
                                        <Link onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} to={`/food/${yokai.food[1]}`} className="yokai-food">
                                            <img className="img-yokai-detail-food" alt={yokai.food[0]} src={yokai.img[i].foodimg} />
                                            {yokai.food[0]}
                                        </Link>
                                    </div>
                                </div>
                                <div className="yokai-detail-stats-wrapper">
                                    <h2 className="yokai-detail-title" style={{ backgroundColor: yokai.bgcolor }}>STATS</h2>
                                    <div className="yokai-detail-stats">
                                        <div className="stats-hp">
                                            <p>HP</p>
                                            <p>{yokai.stats[0]}</p>
                                        </div>
                                        <div className="stats-str">
                                            <p>Str</p>
                                            <p>{yokai.stats[1]}</p>
                                        </div>
                                        <div className="stats-spr">
                                            <p>Spr</p>
                                            <p>{yokai.stats[2]}</p>
                                        </div>
                                        <div className="stats-def">
                                            <p>Def</p>
                                            <p>{yokai.stats[3]}</p>
                                        </div>
                                        <div className="stats-spd">
                                            <p>Spd</p>
                                            <p>{yokai.stats[4]}</p>
                                        </div>
                                    </div>
                                </div>
                                {(yokai.fusion[i].fusion01[0] !== "" || yokai.evolution[i].yokai[0] !== "") &&
                                    <div className="yokai-detail-evolution-wrapper">
                                        <h2 className="yokai-detail-title" style={{ backgroundColor: yokai.bgcolor }}>EVOLUTION</h2>
                                        {(yokai.evolution[i].yokai[0] !== "") &&
                                            <div className="yokai-detail-evolution">
                                                {(yokai.evolution[i].yokai[0] !== yokai.name) &&
                                                    <div className="evolution">
                                                        <Link to={`/yokai-information/${yokai.evolution[i].slug[0]}`}>
                                                            <img className="img-evolution" alt={yokai.evolution[i].yokai[0]} src={yokai.img[i].evoimg7} />
                                                            <p>{yokai.evolution[i].yokai[0]}</p>
                                                        </Link>
                                                    </div>
                                                }
                                                {(yokai.evolution[i].yokai[0] === yokai.name) &&
                                                    <div className="evolution">
                                                        <img className="img-evolution" alt={yokai.evolution[i].yokai[0]} src={yokai.img[i].evoimg7} />
                                                        <p>{yokai.evolution[i].yokai[0]}</p>
                                                    </div>
                                                }
                                                <div className="evolution arrow">
                                                    <p>{yokai.evolution[i].yokai[1]}</p>
                                                    <img className="img-evolution-arrow" alt="arrow" src={yokai.img[i].evoimg8} />
                                                </div>
                                                {(yokai.evolution[i].yokai[2] !== yokai.name) &&
                                                    <div className="evolution">
                                                        <Link to={`/yokai-information/${yokai.evolution[i].slug[1]}`} state={{ yokainame: yokai.evolution[i].yokai[2], bgheader: yokai.evolution[i].yokai[3], }}>
                                                            <img className="img-evolution" alt={yokai.evolution[i].yokai[2]} src={yokai.img[i].evoimg9} />
                                                            <p>{yokai.evolution[i].yokai[2]}</p>
                                                        </Link>
                                                    </div>
                                                }
                                                {(yokai.evolution[i].yokai[2] === yokai.name) &&
                                                    <div className="evolution">
                                                        <img className="img-evolution" alt={yokai.evolution[i].yokai[2]} src={yokai.img[i].evoimg9} />
                                                        <p>{yokai.evolution[i].yokai[2]}</p>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {(yokai.fusion[i].fusion01[0] !== "") &&
                                            <div className="yokai-detail-evolution">
                                                {(yokai.fusion[i].fusion01[0] !== yokai.name && yokai.fusion[i].fusion01[1] === "yokai") &&
                                                    <div className="evolution">
                                                        <Link to={`/yokai-information/${yokai.fusion[i].slug[0]}`} state={{ yokainame: yokai.fusion[i].fusion01[0], bgheader: yokai.fusion[i].fusion01[2], }}>
                                                            <img className="img-evolution" alt={yokai.fusion[i].fusion01[0]} src={yokai.img[i].evoimg1} />
                                                            <p>{yokai.fusion[i].fusion01[0]}</p>
                                                        </Link>
                                                    </div>
                                                }
                                                {(yokai.fusion[i].fusion01[0] === yokai.name || yokai.fusion[i].fusion01[1] === "") &&
                                                    <div className="evolution">
                                                        <img className="img-evolution" alt={yokai.fusion[i].fusion01[0]} src={yokai.img[i].evoimg1} />
                                                        <p>{yokai.fusion[i].fusion01[0]}</p>
                                                    </div>
                                                }
                                                <div className="evolution plus">
                                                    +
                                                </div>
                                                {(yokai.fusion[i].fusion02[0] !== yokai.name && yokai.fusion[i].fusion02[1] === "yokai") &&
                                                    <div className="evolution">
                                                        <Link to={`/yokai-information/${yokai.fusion[i].slug[1]}`} state={{ yokainame: yokai.fusion[i].fusion02[0], bgheader: yokai.fusion[i].fusion02[2], }}>
                                                            <img className="img-evolution" alt={yokai.fusion[i].fusion02[0]} src={yokai.img[i].evoimg2} />
                                                            <p>{yokai.fusion[i].fusion02[0]}</p>
                                                        </Link>
                                                    </div>
                                                }
                                                {(yokai.fusion[i].fusion02[0] === yokai.name || yokai.fusion[i].fusion02[1] === "") &&
                                                    <div className="evolution">
                                                        <img className="img-evolution" alt={yokai.fusion[i].fusion02[0]} src={yokai.img[i].evoimg2} />
                                                        <p>{yokai.fusion[i].fusion02[0]}</p>
                                                    </div>
                                                }
                                                <div className="evolution equal">
                                                    =
                                                </div>
                                                {(yokai.fusion[i].fusion03[0] !== yokai.name) &&
                                                    <div className="evolution">
                                                        <Link to={`/yokai-information/${yokai.fusion[i].slug[2]}`} state={{ yokainame: yokai.fusion[i].fusion03[0], bgheader: yokai.fusion[i].fusion03[2], }}>
                                                            <img className="img-evolution" alt={yokai.fusion[i].fusion02[0]} src={yokai.img[i].evoimg3} />
                                                            <p>{yokai.fusion[i].fusion03[0]}</p>
                                                        </Link>
                                                    </div>
                                                }
                                                {(yokai.fusion[i].fusion03[0] === yokai.name) &&
                                                    <div className="evolution">
                                                        <img className="img-evolution" alt={yokai.fusion[i].fusion02[0]} src={yokai.img[i].evoimg3} />
                                                        <p>{yokai.fusion[i].fusion03[0]}</p>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {(yokai.fusion01[i].fusion04[0] !== "") &&
                                            <div className="yokai-detail-evolution">
                                                {(yokai.fusion01[i].fusion04[0] !== yokai.name && yokai.fusion01[i].fusion04[1] === "yokai") &&
                                                    <div className="evolution">
                                                        <Link to={`/yokai-information/${yokai.fusion01[i].slug[0]}`} state={{ yokainame: yokai.fusion01[i].fusion04[0], bgheader: yokai.fusion01[i].fusion04[2], }}>
                                                            <img className="img-evolution" alt={yokai.fusion01[i].fusion04[0]} src={yokai.img[i].evoimg4} />
                                                            <p>{yokai.fusion01[i].fusion04[0]}</p>
                                                        </Link>
                                                    </div>
                                                }
                                                {(yokai.fusion01[i].fusion04[0] === yokai.name || yokai.fusion01[i].fusion04[1] === "") &&
                                                    <div className="evolution">
                                                        <img className="img-evolution" alt={yokai.fusion01[i].fusion04[0]} src={yokai.img[i].evoimg4} />
                                                        <p>{yokai.fusion01[i].fusion04[0]}</p>
                                                    </div>
                                                }
                                                <div className="evolution plus">
                                                    +
                                                </div>
                                                {(yokai.fusion01[i].fusion05[0] !== yokai.name && yokai.fusion01[i].fusion05[1] === "yokai") &&
                                                    <div className="evolution">
                                                        <Link to={`/yokai-information/${yokai.fusion01[i].slug[1]}`} state={{ yokainame: yokai.fusion01[i].fusion05[0], bgheader: yokai.fusion01[i].fusion05[2], }}>
                                                            <img className="img-evolution" alt={yokai.fusion01[i].fusion05[0]} src={yokai.img[i].evoimg5} />
                                                            <p>{yokai.fusion01[i].fusion05[0]}</p>
                                                        </Link>
                                                    </div>
                                                }
                                                {(yokai.fusion01[i].fusion05[0] === yokai.name || yokai.fusion01[i].fusion05[1] === "") &&
                                                    <div className="evolution">
                                                        <img className="img-evolution" alt={yokai.fusion01[i].fusion05[0]} src={yokai.img[i].evoimg5} />
                                                        <p>{yokai.fusion01[i].fusion05[0]}</p>
                                                    </div>
                                                }
                                                <div className="evolution equal">
                                                    =
                                                </div>
                                                {(yokai.fusion01[i].fusion06[0] !== yokai.name) &&
                                                    <div className="evolution">
                                                        <Link to={`/yokai-information/${yokai.fusion01[i].slug[2]}`} state={{ yokainame: yokai.fusion01[i].fusion06[0], bgheader: yokai.fusion01[i].fusion06[2], }}>
                                                            <img className="img-evolution" alt={yokai.fusion01[i].fusion06[0]} src={yokai.img[i].evoimg6} />
                                                            <p>{yokai.fusion01[i].fusion06[0]}</p>
                                                        </Link>
                                                    </div>
                                                }
                                                {(yokai.fusion01[i].fusion06[0] === yokai.name) &&
                                                    <div className="evolution">
                                                        <img className="img-evolution" alt={yokai.fusion01[i].fusion06[0]} src={yokai.img[i].evoimg6} />
                                                        <p>{yokai.fusion01[i].fusion06[0]}</p>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {(yokai.fusion02[i].fusion07[0] !== "") &&
                                            <div className="yokai-detail-evolution">
                                                {(yokai.fusion02[i].fusion07[0] !== yokai.name && yokai.fusion02[i].fusion07[1] === "yokai") &&
                                                    <div className="evolution">
                                                        <Link to={`/yokai-information/${yokai.fusion02[i].slug[0]}`} state={{ yokainame: yokai.fusion02[i].fusion07[0], bgheader: yokai.fusion02[i].fusion07[2], }}>
                                                            <img className="img-evolution" alt={yokai.fusion02[i].fusion07[0]} src={yokai.img[i].evoimg7} />
                                                            <p>{yokai.fusion02[i].fusion07[0]}</p>
                                                        </Link>
                                                    </div>
                                                }
                                                {(yokai.fusion02[i].fusion07[0] === yokai.name || yokai.fusion02[i].fusion07[1] === "") &&
                                                    <div className="evolution">
                                                        <img className="img-evolution" alt={yokai.fusion02[i].fusion07[0]} src={yokai.img[i].evoimg7} />
                                                        <p>{yokai.fusion02[i].fusion07[0]}</p>
                                                    </div>
                                                }
                                                <div className="evolution plus">
                                                    +
                                                </div>
                                                {(yokai.fusion02[i].fusion08[0] !== yokai.name && yokai.fusion02[i].fusion08[1] === "yokai") &&
                                                    <div className="evolution">
                                                        <Link to={`/yokai-information/${yokai.fusion02[i].slug[1]}`} state={{ yokainame: yokai.fusion02[i].fusion08[0], bgheader: yokai.fusion02[i].fusion08[2], }}>
                                                            <img className="img-evolution" alt={yokai.fusion02[i].fusion08[0]} src={yokai.img[i].evoimg8} />
                                                            <p>{yokai.fusion02[i].fusion08[0]}</p>
                                                        </Link>
                                                    </div>
                                                }
                                                {(yokai.fusion02[i].fusion08[0] === yokai.name || yokai.fusion02[i].fusion08[1] === "") &&
                                                    <div className="evolution">
                                                        <img className="img-evolution" alt={yokai.fusion02[i].fusion08[0]} src={yokai.img[i].evoimg8} />
                                                        <p>{yokai.fusion02[i].fusion08[0]}</p>
                                                    </div>
                                                }
                                                <div className="evolution equal">
                                                    =
                                                </div>
                                                {(yokai.fusion02[i].fusion09[0] !== yokai.name) &&
                                                    <div className="evolution">
                                                        <Link to={`/yokai-information/${yokai.fusion02[i].slug[2]}`} state={{ yokainame: yokai.fusion02[i].fusion09[0], bgheader: yokai.fusion02[i].fusion09[2], }}>
                                                            <img className="img-evolution" alt={yokai.fusion02[i].fusion09[0]} src={yokai.img[i].evoimg9} />
                                                            <p>{yokai.fusion02[i].fusion09[0]}</p>
                                                        </Link>
                                                    </div>
                                                }
                                                {(yokai.fusion02[i].fusion09[0] === yokai.name) &&
                                                    <div className="evolution">
                                                        <img className="img-evolution" alt={yokai.fusion02[i].fusion09[0]} src={yokai.img[i].evoimg9} />
                                                        <p>{yokai.fusion02[i].fusion09[0]}</p>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                                {(yokai.moreinfo[0] !== "" || yokai.moreinfo[1] !== "" || yokai.moreinfo[2] !== "") &&
                                    <div className="yokai-detail-profile-wrapper">
                                        <h2 className="yokai-detail-title" style={{ backgroundColor: yokai.bgcolor }}>PROFILE (Yo-kai Watch 3)</h2>
                                        <div className="yokai-detail-profile-content">
                                            <p>{yokai.moreinfo[0]}</p>
                                            <p>{yokai.moreinfo[1]}</p>
                                            <p>{yokai.moreinfo[2]}</p>
                                        </div>
                                    </div>
                                }
                                <div className="yokai-detail-moveset-wrapper">
                                    <h2 className="yokai-detail-title" style={{ backgroundColor: yokai.bgcolor }}>MOVE SET</h2>
                                    <div className="yokai-detail-moveset-content">
                                        <table cellSpacing={0}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ backgroundColor: "#d7c48a", }}>Attack</td>
                                                    <td>{yokai.moveset[i].attack[0]}</td>
                                                    <td>{yokai.moveset[i].attack[1]}</td>
                                                    <td></td>
                                                    <td>{yokai.moveset[i].attack[2]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ backgroundColor: "#7eaec5", }}>Technique</td>
                                                    <td>{yokai.moveset[i].technique[0]}</td>
                                                    <td>{yokai.moveset[i].technique[1]}</td>
                                                    <td className="img-technique-wrapper"><img alt="technique" className="img-technique" src={yokai.img[i].techimg} /></td>
                                                    <td>{yokai.moveset[i].technique[2]}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ backgroundColor: "#be9ea1", }}>Inspirit</td>
                                                    <td>{yokai.moveset[i].inspirit[0]}</td>
                                                    <td>{yokai.moveset[i].inspirit[1]}</td>
                                                    <td></td>
                                                    <td>{yokai.moveset[i].inspirit[2]}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} style={{ textAlign: 'center', }}>{yokai.moveset[i].desc1}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ backgroundColor: "#addfad", }}>Soultimate</td>
                                                    <td>{yokai.moveset[i].soultimate[0]}</td>
                                                    <td>{yokai.moveset[i].soultimate[1]}</td>
                                                    <td className="img-soultimate-1-wrapper">
                                                        {(yokai.img[i].soutimateimg1 !== "") &&
                                                            <img alt="soultimate" className="img-soultimate-1" src={yokai.img[i].soutimateimg1} />
                                                        }
                                                    </td>
                                                    <td>
                                                        {(yokai.moveset[i].soultimate[2] !== "") &&
                                                            <span>{yokai.moveset[i].soultimate[2]}</span>
                                                        }
                                                        {(yokai.img[i].soutimateimg2 !== "") &&
                                                            <img alt="soultimate" className="img-soultimate-2" src={yokai.img[i].soutimateimg2} />
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5}  style={{ textAlign: 'center', }}>{yokai.moveset[i].desc2}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ backgroundColor: "#addfad", }}>Skill</td>
                                                    <td>{yokai.moveset[i].skill[0]}</td>
                                                    <td>{yokai.moveset[i].skill[1]}</td>
                                                    <td></td>
                                                    <td>{yokai.moveset[i].skill[2]}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} style={{ textAlign: 'center', }}>{yokai.moveset[i].desc3}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="yokai-detail-share-wrapper">
                                    <h2 className="yokai-detail-title" style={{ backgroundColor: yokai.bgcolor }}>SHARE ON SOCIALS</h2>
                                    <div className="yokai-socials-wrapper">
                                        <FacebookShareButton url={window.location.href} image={yokai.yokaiimg} title={`View ${yokai.name} detail in `} description={yokai.moreinfo[0]} className="social-share-button">
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                        <TwitterShareButton url={window.location.href} image={yokai.yokaiimg} title={`View ${yokai.name} detail in `} description={yokai.moreinfo[0]} className="social-share-button">
                                            <TwitterIcon size={32} round />
                                        </TwitterShareButton>
                                        <TelegramShareButton url={window.location.href} image={yokai.yokaiimg} title={`View ${yokai.name} detail in `} description={yokai.moreinfo[0]} className="social-share-button">
                                            <TelegramIcon size={32} round />
                                        </TelegramShareButton>
                                        <WhatsappShareButton url={window.location.href} image={yokai.yokaiimg} title={`View ${yokai.name} detail in `} description={yokai.moreinfo[0]} className="social-share-button">
                                            <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                        <RedditShareButton url={window.location.href} image={yokai.yokaiimg} title={`View ${yokai.name} detail in `} description={yokai.moreinfo[0]} className="social-share-button">
                                            <RedditIcon size={32} round />
                                        </RedditShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default YokaiInfo;