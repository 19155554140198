import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import '../../assets/css/RareSpecialSoul.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import Bubble_Beth_Soul from '../../assets/img/specialsoul/Bubble_Beth_Soul.png'
import Charge_Soul from '../../assets/img/specialsoul/Charge_Soul.png'
import El_Gutso_Grande_Sl from '../../assets/img/specialsoul/El_Gutso_Grande_Sl.png'
import Eyesoar_Soul from '../../assets/img/specialsoul/Eyesoar_Soul.png'
import Hairum_Scarum_Sl from '../../assets/img/specialsoul/Hairum_Scarum_Sl.png'
import Purification_Soul from '../../assets/img/specialsoul/Purification_Soul.png'
import Snidewinder_Soul from '../../assets/img/specialsoul/Snidewinder_Soul.png'
import Spiky_Soul from '../../assets/img/specialsoul/Spiky_Soul.png'
import Statiking_Soul from '../../assets/img/specialsoul/Statiking_Soul.png'
import Steaking_Soul from '../../assets/img/specialsoul/Steaking_Soul.png'
import Whirlweasel_Soul from '../../assets/img/specialsoul/Whirlweasel_Soul.png'
import ogImage from '../../assets/img/og-image.jpg';

const RARESpecialSoul = props => {
    let navigate = useNavigate();

    return(
        <div id="rare-special-soul" className="content-wrapper">
            <Helmet>
                <title>RARE Special Soul | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:description" content="The site contains informations about all the yo-kais, their favorite foods, locations, items, evolutions, stats, etc." />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={ogImage} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#7A9BBF", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">RARE Special Soul</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rare-special-soul-content">
                <div className="container">
                    <div className="rare-special-soul-header">
                        <span className="special-soul">Soul</span>
                        <span className="rare-special-soul-plus"></span>
                        <span className="special-soul">Fused with</span>
                        <span className="rare-special-soul-equal"></span>
                        <span className="special-soul">Result</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/rawry">
                                <img alt="Rawry Soul" className="img-rare-special-soul" src={Hairum_Scarum_Sl} />
                                <span className="rare-special-soul-title">Rawry Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/snitchin">
                                <img alt="Snitchin Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Snitchin Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Spiky Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Spiky Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>All Yo-kai take more damage from Inspirits.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/whirlweasel">
                                <img alt="Whirlweasel Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Whirlweasel Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/el-gutso">
                                <img alt="El Gutso Grande Sl." className="img-rare-special-soul" src={El_Gutso_Grande_Sl} />
                                <span className="rare-special-soul-title">El Gutso Grande Sl.</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Slicer Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Slicer Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Ignores Charms affecting target Yo-kai if attacking.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/snidewinder">
                                <img alt="Whirlweasel Soul" className="img-rare-special-soul" src={Snidewinder_Soul} />
                                <span className="rare-special-soul-title">Snidewinder Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/sigh-durr">
                                <img alt="Sigh-Durr Soul" className="img-rare-special-soul" src={El_Gutso_Grande_Sl} />
                                <span className="rare-special-soul-title">Sigh-Durr Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Combo Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Combo Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Can make combo attacks, even linked in a column.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/swelterrier">
                                <img alt="Swelterrier Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Swelterrier Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/steaking">
                                <img alt="Steaking Soul" className="img-rare-special-soul" src={Steaking_Soul} />
                                <span className="rare-special-soul-title">Steaking Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Scorching Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Scorching Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Sets Technique to Incinerate.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/faux-kappa">
                                <img alt="Faux Kappa Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Faux Kappa Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/bubble-beth">
                                <img alt="Bubble Beth Soul" className="img-rare-special-soul" src={Bubble_Beth_Soul} />
                                <span className="rare-special-soul-title">Bubble Beth Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Soaking Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Soaking Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Sets Technique to Waterfall.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/statiking">
                                <img alt="Statiking Soul" className="img-rare-special-soul" src={Statiking_Soul} />
                                <span className="rare-special-soul-title">Statiking Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/papa-bolt">
                                <img alt="Papa Bolt Soul" className="img-rare-special-soul" src={Bubble_Beth_Soul} />
                                <span className="rare-special-soul-title">Papa Bolt Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Sparking Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Sparking Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Sets Technique to Voltage.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/eyesoar">
                                <img alt="Eyesoar Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Eyesoar Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/hairum-scarum">
                                <img alt="Hairum Scarum Sl." className="img-rare-special-soul" src={Hairum_Scarum_Sl} />
                                <span className="rare-special-soul-title">Hairum Scarum Sl.</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Spacedust Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Spacedust Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Sets Technique to Meteor.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/blizzaria">
                                <img alt="Blizzaria Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Blizzaria Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/chilhuahua">
                                <img alt="Chilhuahua Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Chilhuahua Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Subzero Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Subzero Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Sets Technique to Blizzard.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/smogmella">
                                <img alt="Smogmella Soul" className="img-rare-special-soul" src={Statiking_Soul} />
                                <span className="rare-special-soul-title">Smogmella Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/tenguriginal">
                                <img alt="Tenguriginal Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Tenguriginal Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Spin Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Spin Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Sets Technique to Storm.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/nurse-tongus">
                                <img alt="Nurse Tongus Soul" className="img-rare-special-soul" src={Bubble_Beth_Soul} />
                                <span className="rare-special-soul-title">Nurse Tongus Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/washogun">
                                <img alt="Washogun Soul" className="img-rare-special-soul" src={El_Gutso_Grande_Sl} />
                                <span className="rare-special-soul-title">Washogun Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Purification Soul" className="img-rare-special-soul" src={Purification_Soul} />
                            <span className="rare-special-soul-title">Purification Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Yo-kai may also purify an ally when healing.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/goruma">
                                <img alt="Goruma Soul" className="img-rare-special-soul" src={Steaking_Soul} />
                                <span className="rare-special-soul-title">Goruma Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/casanono">
                                <img alt="Casanono Soul" className="img-rare-special-soul" src={Statiking_Soul} />
                                <span className="rare-special-soul-title">Casanono Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Purification Soul" className="img-rare-special-soul" src={Purification_Soul} />
                            <span className="rare-special-soul-title">Purification Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Immune to critical damage.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/impass">
                                <img alt="Impass Soul" className="img-rare-special-soul" src={Steaking_Soul} />
                                <span className="rare-special-soul-title">Impass Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/robonyan">
                                <img alt="Robonyan Soul" className="img-rare-special-soul" src={Steaking_Soul} />
                                <span className="rare-special-soul-title">Robonyan Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Shielding Soul" className="img-rare-special-soul" src={Purification_Soul} />
                            <span className="rare-special-soul-title">Shielding Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Guard automatically after moving.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/chummer">
                                <img alt="Chummer Soul" className="img-rare-special-soul" src={Snidewinder_Soul} />
                                <span className="rare-special-soul-title">Chummer Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/castelius-max">
                                <img alt="Castelius Max Soul" className="img-rare-special-soul" src={Steaking_Soul} />
                                <span className="rare-special-soul-title">Castelius Max Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Peerless Soul" className="img-rare-special-soul" src={Purification_Soul} />
                            <span className="rare-special-soul-title">Peerless Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Nullifies one Soultimate Move or attack.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/corptain">
                                <img alt="Corptain Soul" className="img-rare-special-soul" src={El_Gutso_Grande_Sl} />
                                <span className="rare-special-soul-title">Corptain Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/nird">
                                <img alt="Nird Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Nird Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Sinister Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Sinister Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>All healing Techniques will be weaker.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/statikid">
                                <img alt="Statikid Soul" className="img-rare-special-soul" src={Statiking_Soul} />
                                <span className="rare-special-soul-title">Statikid Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/robonoko">
                                <img alt="Robonoko Soul" className="img-rare-special-soul" src={Snidewinder_Soul} />
                                <span className="rare-special-soul-title">Robonoko Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Charge Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Charge Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Your Soul Meter fills as you move.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/dimmy">
                                <img alt="Dimmy Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Dimmy Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/blandon">
                                <img alt="Blandon Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Blandon Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Stealth Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Stealth Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Will not become target of attacks.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/greesel">
                                <img alt="Greesel Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Greesel Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/allnyta">
                                <img alt="Allnyta Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Allnyta Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Supernatural Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Supernatural Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Own Inspirits will always succeed.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/cupistol">
                                <img alt="Cupistol Soul" className="img-rare-special-soul" src={Statiking_Soul} />
                                <span className="rare-special-soul-title">Cupistol Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/pinkipoo">
                                <img alt="Pinkipoo Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Pinkipoo Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Superstar Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Superstar Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Improves chances of befriending an enemy.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/afronaut">
                                <img alt="Afronaut Soul" className="img-rare-special-soul" src={Bubble_Beth_Soul} />
                                <span className="rare-special-soul-title">Afronaut Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/slax">
                                <img alt="Pinkipoo Soul" className="img-rare-special-soul" src={Hairum_Scarum_Sl} />
                                <span className="rare-special-soul-title">Slax Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Weightless Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Weightless Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Ignores negative floor effects when moving.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/pandanoko">
                                <img alt="Pandanoko Soul" className="img-rare-special-soul" src={Snidewinder_Soul} />
                                <span className="rare-special-soul-title">Pandanoko Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/starry-noko">
                                <img alt="Pinkipoo Soul" className="img-rare-special-soul" src={Snidewinder_Soul} />
                                <span className="rare-special-soul-title">Starry Noko Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Summoner's Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Summoner's Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Will make Wisps appear more often.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/t-wrecks">
                                <img alt="T-Wrecks Soul" className="img-rare-special-soul" src={Snidewinder_Soul} />
                                <span className="rare-special-soul-title">T-Wrecks Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/urnfulfilled">
                                <img alt="Urnfulfilled Soul" className="img-rare-special-soul" src={Steaking_Soul} />
                                <span className="rare-special-soul-title">Urnfulfilled Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Blowaway Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Blowaway Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Knocks back an enemy by one tile when attacking.</span>
                    </div>
                    
                    <div className="rare-special-soul">
                        <span>
                            <Link className="special-soul" to="/yokai-information/rubeus-j">
                                <img alt="Rubeus J Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Rubeus J Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link className="special-soul" to="/yokai-information/ed-zoff">
                                <img alt="Urnfulfilled Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Ed Zoff Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span className="special-soul">
                            <img alt="Red Rush Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Red Rush Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Can act again immediately after defeating an enemy.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RARESpecialSoul;