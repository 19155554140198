import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgHamburger from '../../assets/img/food/Hamburger.png';
import imgCheeseburger from '../../assets/img/food/Cheeseburger.png';
import imgDoubleBurger from '../../assets/img/food/Double_Burger.png';
import imgStarBurgert from '../../assets/img/food/Star_Burger.png';
import imgStarBurgerDeluxe from '../../assets/img/food/Star_Burger_Deluxe.png';
import imgNomBurger from '../../assets/img/food/Nom_Burger.png';
import imgStarBurgerEX from '../../assets/img/food/Star_Burger_EX.png';

const Hamburgers = props => {
    let navigate = useNavigate();

    return(
        <div id="hamburgers" className="content-wrapper">
            <Helmet>
                <title>Hamburgers | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgHamburger} />
                <meta property="og:url" content={imgHamburger} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#82AA2A", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Hambugers</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Hamburgers-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Hamburger</td>
                                <td><img alt="Hamburger" className="img-food-content" src={imgHamburger} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Cheeseburger</td>
                                <td><img alt="Cheeseburger" className="img-food-content" src={imgCheeseburger} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Double Burger</td>
                                <td><img alt="Double Burger" className="img-food-content" src={imgDoubleBurger} /></td>
                                <td>Recovers a lot of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Star Burger</td>
                                <td><img alt="Star Burger" className="img-food-content" src={imgStarBurgert} /></td>
                                <td>Recovers a lot of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Star Burger Deluxe</td>
                                <td><img alt="Star Burger Deluxe" className="img-food-content" src={imgStarBurgerDeluxe} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Nom Burger</td>
                                <td><img alt="Nom Burger" className="img-food-content" src={imgNomBurger} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Extreme Star Burger</td>
                                <td><img alt="Extreme Star Burger" className="img-food-content" src={imgStarBurgerEX} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Hamburgers;