import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Password.css';
import Password2 from '../../assets/jsons/psyw2db.json';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';

const Password2DE = props => {
    let navigate = useNavigate();

    return(
        <div id="attitudes" className="content-wrapper">
            <Helmet>
                <title>PW Yo-kai Watch 2 (DE) | YokaiDex Where you can find all information from Yokai-Watch games!</title>
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#E85A3D", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">PW Yo-kai Watch 2 (DE)</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="password-content">
                <div className="container">
                    <div className="table-responsive">
                        <table cellSpacing={0} className="table-password">
                            <thead>
                                <tr>
                                    <th>Password</th>
                                    <th>Effect</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (
                                        Password2.de.map((password) => {
                                            return (
                                                <tr key={password.key} style={{ backgroundColor: password.bgcolor }}>
                                                    <td>{password.key}</td>
                                                    <td>{password.effect}</td>
                                                </tr>
                                            )
                                        })
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Password2DE;