import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import '../../assets/css/Password.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import ogImage from '../../assets/img/og-image.jpg';

const Password1 = props => {
    let navigate = useNavigate();

    return(
        <div id="password" className="content-wrapper">
            <Helmet>
                <title>PW Yo-kai Watch 1 | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:description" content="The site contains informations about all the yo-kais, their favorite foods, locations, items, evolutions, stats, etc." />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={ogImage} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#7A9BBF", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">PW Yo-kai Watch 1</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="password-content">
                <div className="container">
                    <div className="password-1-wrapper">
                        <div className="password-1-content-wrapper">
                            <div className="password-1-content" style={{ backgroundColor: '#E85A3D' }}>
                                <Link to={`/password-1/en-us`}>EN (US)</Link>
                            </div>
                            <div className="password-1-content" style={{ backgroundColor: '#FFC648' }}>
                                <Link to={`/password-1/es-us`}>ES (US)</Link>
                            </div>
                        </div>
                        <div className="password-1-content-wrapper">
                            <div className="password-1-content" style={{ backgroundColor: '#6DAA73' }}>
                                <Link to={`/password-1/fr-us`}>FR (US)</Link>
                            </div>
                            <div className="password-1-content" style={{ backgroundColor: '#AA90B4' }}>
                                <Link to={`/password-1/en-eu`}>EN (EU)</Link>
                            </div>
                        </div>
                        <div className="password-1-content-wrapper">
                            <div className="password-1-content" style={{ backgroundColor: '#FBA6CC' }}>
                                <Link to={`/password-1/es-eu`}>ES (EU)</Link>
                            </div>
                            <div className="password-1-content" style={{ backgroundColor: '#68E7EC' }}>
                                <Link to={`/password-1/fr-eu`}>FR (EU)</Link>
                            </div>
                        </div>
                        <div className="password-1-content-wrapper">
                            <div className="password-1-content" style={{ backgroundColor: '#FF8041' }}>
                                <Link to={`/password-1/de`}>DE</Link>
                            </div>
                            <div className="password-1-content" style={{ backgroundColor: '#489999' }}>
                                <Link to={`/password-1/it`}>IT</Link>
                            </div>
                        </div>
                        <div className="password-1-content-wrapper">
                            <div className="password-1-content" style={{ backgroundColor: '#4183A4' }}>
                                <Link to={`/password-1/ko`}>KO</Link>
                            </div>
                            <div className="password-1-content" style={{ backgroundColor: '#758EFF' }}>
                                <Link to={`/password-1/ja`}>JA</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Password1;