import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams, useNavigate } from 'react-router-dom';

import '../../assets/css/QRCode.css';
import qrcode from '../../assets/jsons/qrcode.json';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';

const QRCodeList = props => {
    const {slug} = useParams();
    let navigate = useNavigate();

    for (var i = 0; i < 5; i++) {
        return(
            <div id="qr-code" className="content-wrapper">
                {
                    // eslint-disable-next-line
                    qrcode.filter((qr) => slug === qr.slug).map((qr) => (
                        <Helmet key={qr.key}>
                            <title>{qr.name} | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                            <meta property="og:image" content={qr.icon} />
                            <meta property="og:url" content={qr.icon}></meta>
                        </Helmet>
                    ))
                }
                <div className="header-wrapper">
                    <div className="container">
                        {
                            // eslint-disable-next-line
                            qrcode.filter((qr) => slug === qr.slug).map((qr) => (
                                <div key={qr.key} className="header-content" style={{ backgroundColor: qr.bgcolor, }}>
                                    <div className="header-title-wrapper">
                                        <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                                        <h1 className="header-title">{qr.name}</h1>
                                        <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="qr-code-list-wrapper">
                    <div className="container">
                        <div className="qr-list-content">
                        {
                            // eslint-disable-next-line
                            qrcode.filter((qr) => slug === qr.slug).map((qr) => (
                                <ul key={qr.key}>
                                    {(qr.coin[i].title[0] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[0]}`}>{qr.coin[i].title[0]}</Link></li>
                                    }
                                    {(qr.coin[i].title[1] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[1]}`}>{qr.coin[i].title[1]}</Link></li>
                                    }
                                    {(qr.coin[i].title[2] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[2]}`}>{qr.coin[i].title[2]}</Link></li>
                                    }
                                    {(qr.coin[i].title[3] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[3]}`}>{qr.coin[i].title[3]}</Link></li>
                                    }
                                    {(qr.coin[i].title[4] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[4]}`}>{qr.coin[i].title[4]}</Link></li>
                                    }
                                    {(qr.coin[i].title[5] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[5]}`}>{qr.coin[i].title[5]}</Link></li>
                                    }
                                    {(qr.coin[i].title[6] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[6]}`}>{qr.coin[i].title[6]}</Link></li>
                                    }
                                    {(qr.coin[i].title[7] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[7]}`}>{qr.coin[i].title[7]}</Link></li>
                                    }
                                    {(qr.coin[i].title[8] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[8]}`}>{qr.coin[i].title[8]}</Link></li>
                                    }
                                    {(qr.coin[i].title[9] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[9]}`}>{qr.coin[i].title[9]}</Link></li>
                                    }
                                    {(qr.coin[i].title[10] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[10]}`}>{qr.coin[i].title[10]}</Link></li>
                                    }
                                    {(qr.coin[i].title[11] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[11]}`}>{qr.coin[i].title[11]}</Link></li>
                                    }
                                    {(qr.coin[i].title[12] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[12]}`}>{qr.coin[i].title[12]}</Link></li>
                                    }
                                    {(qr.coin[i].title[13] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[13]}`}>{qr.coin[i].title[13]}</Link></li>
                                    }
                                    {(qr.coin[i].title[14] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[14]}`}>{qr.coin[i].title[14]}</Link></li>
                                    }
                                    {(qr.coin[i].title[15] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[15]}`}>{qr.coin[i].title[15]}</Link></li>
                                    }
                                    {(qr.coin[i].title[16] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[16]}`}>{qr.coin[i].title[16]}</Link></li>
                                    }
                                    {(qr.coin[i].title[17] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[17]}`}>{qr.coin[i].title[17]}</Link></li>
                                    }
                                    {(qr.coin[i].title[18] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[18]}`}>{qr.coin[i].title[18]}</Link></li>
                                    }
                                    {(qr.coin[i].title[19] !== "") && 
                                        <li><Link to={`/qr-code/${qr.slug}/${qr.coin[i].slug[19]}`}>{qr.coin[i].title[19]}</Link></li>
                                    }
                                </ul>
                            ))
                        }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default QRCodeList;