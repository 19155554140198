import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgCheesePizza from '../../assets/img/food/Hi-Cheese_Pizza.png';
import imgPizzaMargherita from '../../assets/img/food/Pizza_Margherita.png';
import imgGarlicChickenPizza from '../../assets/img/food/Garlic_Chicken_Pizza.png';
import imgCrispyBaconPizza from '../../assets/img/food/Crispy_Bacon_Pizza.png';
import imgStarryPizza from '../../assets/img/food/Starry_Sky_Pizza.png';

const Pizza = props => {
    let navigate = useNavigate();

    return(
        <div id="pizza" className="content-wrapper">
            <Helmet>
                <title>Pizza | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgCheesePizza} />
                <meta property="og:url" content={imgCheesePizza} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#D45D13", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Pizza</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pizza-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                                <td>Cheese Pizza</td>
                                <td><img alt="Cheese Pizza" className="img-food-content" src={imgCheesePizza} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Pizza Margherita</td>
                                <td><img alt="Pizza Margherita" className="img-food-content" src={imgPizzaMargherita} /></td>
                                <td>Recovers a lot of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Garlic Chicken Pizza</td>
                                <td><img alt="Garlic Chicken Pizza" className="img-food-content" src={imgGarlicChickenPizza} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Crispy Bacon Pizza</td>
                                <td><img alt="Crispy Bacon Pizza" className="img-food-content" src={imgCrispyBaconPizza} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Star Pizza</td>
                                <td><img alt="Star Pizza" className="img-food-content" src={imgStarryPizza} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Pizza;