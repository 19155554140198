import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { BrowserView, MobileView } from 'react-device-detect';

import '../../assets/css/Medallium.css';
import yokais from '../../assets/jsons/yokaidb.json';
import iconSearch from '../../assets/img/icon-search.svg';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import ogImage from '../../assets/img/og-image.jpg';

import EmptyList from './EmptyList.js';

const Medallium = props => {
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [selectedRank, setSelectedRank] = useState('');
    const [selectedAttribute, setSelectedAttribute] = useState('');
    const [selectedTribe, setSelectedTribe] = useState('');
    const filteredAttribute = yokais.filter((yokai) => selectedAttribute === yokai.attribute);
    const filteredTribe = yokais.filter((yokai) => selectedTribe === yokai.tribe);
    const filteredRank = yokais.filter((yokai) => selectedRank === yokai.rank[0]);
    const filteredRankTribe = yokais.filter((yokai) => selectedRank === yokai.rank[0] && selectedTribe === yokai.tribe);
    const filteredAtttributeTribe = yokais.filter((yokai) => selectedAttribute === yokai.attribute && selectedTribe === yokai.tribe);
    const filteredRankAtttribute = yokais.filter((yokai) => selectedRank === yokai.rank[0] && selectedAttribute === yokai.attribute);
    const filteredAll = yokais.filter((yokai) => selectedRank === yokai.rank[0] && selectedAttribute === yokai.attribute && selectedTribe === yokai.tribe);
    let navigate = useNavigate();
    
    const handleRankChange = (e) => {
        setSelectedRank(e.target.value);
    };
    const handleAttributeChange = (e) => {
        setSelectedAttribute(e.target.value);
    };
    const handleTribeChange = (e) => {
        setSelectedTribe(e.target.value);
    };

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = yokais.filter(
                (item) => {
                    return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
                }
            )
            setFilteredResults(filteredData)
        }
        else{
            setFilteredResults(yokais)
        }
    }

    return (
        <div className="medallium">
            <Helmet>
                <title>Medallium | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:description" content="The site contains informations about all the yo-kais, their favorite foods, locations, items, evolutions, stats, etc." />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={ogImage} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#E85A3D", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Medallium</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="medallium-list">
                <div className="container">
                    <div className="yokai-filter-wrapper">
                        <div className="yokai-filter-left">
                            <img alt="search" className="icon-search" src={iconSearch} />
                            <input 
                                onChange={(e) => searchItems(e.target.value)}
                                className="yokai-search"
                                placeholder="Search..."
                            />
                        </div>
                        <div className="yokai-filter-right">
                            <select onChange={handleRankChange} name="rank" id="filter-rank">
                                <option value="">All rank</option>
                                <option value="S">S</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                                <option value="E">E</option>
                            </select>
                            <select onChange={handleAttributeChange} name="rank" id="filter-attribute">
                                <option value="">All attribute</option>
                                <option value="Drain">Drain</option>
                                <option value="Earth">Earth</option>
                                <option value="Fire">Fire</option>
                                <option value="Ice">Ice</option>
                                <option value="Lightning">Lightning</option>
                                <option value="Restoration">Restoration</option>
                                <option value="Water">Water</option>
                                <option value="Wind">Wind</option>
                            </select>
                            <select onChange={handleTribeChange} name="tribe" id="filter-tribe">
                                <option value="">All tribe</option>
                                <option value="Brave">Brave</option>
                                <option value="Charming">Charming</option>
                                <option value="Eerie">Eerie</option>
                                <option value="Enma">Enma</option>
                                <option value="Heartful">Heartful</option>
                                <option value="Mysterious">Mysterious</option>
                                <option value="Shady">Shady</option>
                                <option value="Slippery">Slippery</option>
                                <option value="Tough">Tough</option>
                                <option value="Wandroid">Wandroid</option>
                                <option value="Wicked">Wicked</option>
                            </select>
                        </div>
                    </div>
                    <BrowserView className="yokai-list-pc-wrapper">
                        <ul>
                            {
                                searchInput.length > 1 && selectedRank === "" && selectedRank === "" && selectedTribe === "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                searchInput.length > 1 && selectedRank !== "" && selectedAttribute === ""  && selectedTribe === "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.filter((yokai) => selectedRank === yokai.rank[0]).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                searchInput.length > 1 && selectedRank === "" && selectedAttribute === "" && selectedTribe !== "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.filter((yokai) => selectedTribe === yokai.tribe).sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                searchInput.length > 1 && selectedRank !== "" && selectedAttribute === "" && selectedTribe !== "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.filter((yokai) => selectedRank === yokai.rank[0] && selectedTribe === yokai.tribe).sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                searchInput.length > 1 && selectedRank !== "" && selectedAttribute !== "" && selectedTribe === "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.filter((yokai) => selectedRank === yokai.rank[0] && selectedAttribute === yokai.attribute).sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                searchInput.length > 1 && selectedRank === "" && selectedAttribute !== "" && selectedTribe === "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.filter((yokai) => selectedAttribute === yokai.attribute).sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                searchInput.length > 1 && selectedRank !== "" && selectedAttribute !== "" && selectedTribe !== "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.filter((yokai) => selectedRank === yokai.rank[0] && selectedAttribute === yokai.attribute && selectedTribe === yokai.tribe).sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                selectedRank === "" && selectedAttribute === "" && selectedTribe === "" ?
                                (
                                    yokais.map((yokai) => {
                                        return (
                                            <LazyLoad key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper" once>
                                                <li>
                                                    <span className="bg-color-pc"></span>
                                                    <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                        <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                        <div className="yokai-name-pc-wrapper">
                                                            <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                        </div>
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key}>
                                                                        <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                        <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                        <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                    </Link>
                                                </li>
                                            </LazyLoad>
                                        )
                                    })
                                )
                                :
                                selectedRank !== "" && selectedAttribute === "" && selectedTribe === "" ?
                                (
                                    filteredRank.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredRank.map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                selectedRank === "" && selectedAttribute === "" && selectedTribe !== "" ?
                                (
                                    filteredTribe.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredTribe.sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                selectedRank !== "" && selectedAttribute === "" && selectedTribe !== "" ?
                                (
                                    filteredRankTribe.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredRankTribe.sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                selectedRank === "" && selectedAttribute !== "" && selectedTribe === "" ?
                                (
                                    filteredAttribute.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredAttribute.sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                selectedRank === "" && selectedAttribute !== "" && selectedTribe !== "" ?
                                (
                                    filteredAtttributeTribe.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredAtttributeTribe.sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                selectedRank !== "" && selectedAttribute !== "" && selectedTribe === "" ?
                                (
                                    filteredRankAtttribute.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredRankAtttribute.sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                (
                                    filteredAll.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredAll.sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} className="yokai-content-pc-wrapper">
                                                <span className="bg-color-pc"></span>
                                                <Link className="yokai-content-pc" to={`/yokai-information/${yokai.slug}`}>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="img-yokai-wrapper-pc">
                                                                    <img className="img-yokai-pc" alt={yokai.name} src={data.yokaiimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="meddallium-yokai-number-pc" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                    <div className="yokai-name-pc-wrapper">
                                                        <h3 className="yokai-name-pc">{yokai.name}</h3>
                                                    </div>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key}>
                                                                    <img className="img-rank-pc" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-trible-pc" alt={yokai.tribe} src={data.tribeimg} />
                                                                    <img className="img-attribute-pc" alt={yokai.attribute} src={data.attrimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                            }
                        </ul>
                    </BrowserView>
                    <MobileView className="yokai-list-wrapper">
                        <ul>
                            {
                                searchInput.length > 1 && selectedRank === "" && selectedRank === "" && selectedTribe === "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                searchInput.length > 1 && selectedRank !== "" && selectedAttribute === ""  && selectedTribe === "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.filter((yokai) => selectedRank === yokai.rank[0]).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                searchInput.length > 1 && selectedRank === "" && selectedAttribute === "" && selectedTribe !== "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.filter((yokai) => selectedTribe === yokai.tribe).sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                searchInput.length > 1 && selectedRank !== "" && selectedAttribute === "" && selectedTribe !== "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.filter((yokai) => selectedRank === yokai.rank[0] && selectedTribe === yokai.tribe).sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                searchInput.length > 1 && selectedRank !== "" && selectedAttribute !== "" && selectedTribe === "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.filter((yokai) => selectedRank === yokai.rank[0] && selectedAttribute === yokai.attribute).sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                searchInput.length > 1 && selectedRank === "" && selectedAttribute !== "" && selectedTribe === "" ?
                                (
                                    filteredResults.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredResults.filter((yokai) => selectedAttribute === yokai.attribute).sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                selectedRank === "" && selectedAttribute === "" && selectedTribe === "" ?
                                (
                                    yokais.map((yokai) => {
                                        return (
                                            <LazyLoad key={yokai.key} style={{ backgroundColor: yokai.bgcolor }} once>
                                                <li>
                                                    <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                        <span className="yokai-left-wrapper">
                                                            {
                                                                yokai.img.map((data) => {
                                                                    return (
                                                                        <span key={yokai.key} className="img-yokai-wrapper">
                                                                            <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                        </span>
                                                                    )
                                                                })
                                                            }
                                                            <span className="yokai-name-wrapper">
                                                                <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                                <h3 className="yokai-name">{yokai.name}</h3>
                                                            </span>
                                                        </span>
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="yokai-right-wrapper">
                                                                        <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                        <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                        <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="bg-color"></span>
                                                    </Link>
                                                </li>
                                            </LazyLoad>
                                        )
                                    })
                                )
                                :
                                selectedRank !== "" && selectedAttribute === "" && selectedTribe === "" ?
                                (
                                    filteredRank.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredRank.map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                selectedRank === "" && selectedAttribute === "" && selectedTribe !== "" ?
                                (
                                    filteredTribe.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredTribe.sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                selectedRank !== "" && selectedAttribute === "" && selectedTribe !== "" ?
                                (
                                    filteredRankTribe.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredRankTribe.sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                selectedRank === "" && selectedAttribute !== "" && selectedTribe === "" ?
                                (
                                    filteredAttribute.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredAttribute.sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                selectedRank === "" && selectedAttribute !== "" && selectedTribe !== "" ?
                                (
                                    filteredAtttributeTribe.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredAtttributeTribe.sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                selectedRank !== "" && selectedAttribute !== "" && selectedTribe === "" ?
                                (
                                    filteredRankAtttribute.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredRankAtttribute.sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                                :
                                (
                                    filteredAll.length === 0 ?
                                        <EmptyList />
                                    :
                                    filteredAll.sort((a,b) => a.rank[1].localeCompare(b.rank[1])).map((yokai) => {
                                        return (
                                            <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                                <Link className="yokai-content" to={`/yokai-information/${yokai.slug}`}>
                                                    <span className="yokai-left-wrapper">
                                                        {
                                                            yokai.img.map((data) => {
                                                                return (
                                                                    <span key={yokai.key} className="img-yokai-wrapper">
                                                                        <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        <span className="yokai-name-wrapper">
                                                            <span className="meddallium-yokai-number" style={{ backgroundColor: yokai.bgcolor }}>#{yokai.key}</span>
                                                            <h3 className="yokai-name">{yokai.name}</h3>
                                                        </span>
                                                    </span>
                                                    {
                                                        yokai.img.map((data) => {
                                                            return (
                                                                <span key={yokai.key} className="yokai-right-wrapper">
                                                                    <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                                    <img className="img-attribute" alt={yokai.attribute} src={data.attrimg} />
                                                                    <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    <span className="bg-color"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                )
                            }
                        </ul>
                    </MobileView>
                </div>
            </div>
        </div>
    )
}

export default Medallium;