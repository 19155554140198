import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgChickenCurry from '../../assets/img/food/Chicken_Curry.png';
import imgLambCurry from '../../assets/img/food/Lamb_Curry.png';
import imgVeggieCurry from '../../assets/img/food/Veggie_Curry.png';
import imgSeafoodCurry from '../../assets/img/food/Seafood_Curry.png';
import imgBusterCurry from '../../assets/img/food/Buster_Curry.png';

const Curry = props => {
    let navigate = useNavigate();

    return(
        <div id="curry" className="content-wrapper">
            <Helmet>
                <title>Curry | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgChickenCurry} />
                <meta property="og:url" content={imgChickenCurry} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#A05C30", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Curry</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="curry-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Chicken Curry</td>
                                <td><img alt="Chicken Curry" className="img-food-content" src={imgChickenCurry} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Lamb Curry</td>
                                <td><img alt="Lamb Curry" className="img-food-content" src={imgLambCurry} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Veggie Curry</td>
                                <td><img alt="Veggie Curry" className="img-food-content" src={imgVeggieCurry} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Seafood Curry</td>
                                <td><img alt="Seafood Curry" className="img-food-content" src={imgSeafoodCurry} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Buster Curry</td>
                                <td><img alt="Buster Curry" className="img-food-content" src={imgBusterCurry} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Curry;