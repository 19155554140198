import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';

import '../../assets/css/QRCode.css';
import qrcode from '../../assets/jsons/qrcode.json';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import ogImage from '../../assets/img/og-image.jpg';

const QRCode = props => {
    let navigate = useNavigate();

    return(
        <div id="qr-code" className="content-wrapper">
            <Helmet>
                <title>QR Code | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:description" content="The site contains informations about all the yo-kais, their favorite foods, locations, items, evolutions, stats, etc." />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={ogImage} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#FFC648", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">QR Code</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="qr-code-content-wrapper">
                <div className="container">
                    <div className="qr-code-content">
                        {
                            qrcode.map((qr) => {
                                return(
                                    <div key={qr.key} className="qr-code" style={{ backgroundColor: qr.bgcolor }}>
                                        <Link to={`/qr-code/${qr.slug}`}>
                                            <img alt={qr.name} className="icon-qr" src={qr.icon} />
                                            {qr.name}
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QRCode;