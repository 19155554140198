import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgCheesecake from '../../assets/img/food/Cheesecake.png';
import imgCupcake from '../../assets/img/food/Cupcake-0.png';
import imgShortcake from '../../assets/img/food/Shortcake.png';
import imgCherryPie from '../../assets/img/food/Cherry_Pie-0.png';
import imgChocolateBanana from '../../assets/img/food/Chocolate_Banana.png';
import imgSweetRiceCakes from '../../assets/img/food/Sweet_Rice_Cakes.png';
import imgRoyalPancakes from '../../assets/img/food/Royal_Pancakes.png';
import imgJumboParfait from '../../assets/img/food/Jumbo_Parfait.png';
import imgSpiritDoughnut from '../../assets/img/food/Spirit_Doughnut2.png';
import imgSoulDoughnut from '../../assets/img/food/Soul_Doughnut2.png';
import imgSpecterDoughnut from '../../assets/img/food/Specter_Doughnut.png';

const Sweets = props => {
    let navigate = useNavigate();

    return(
        <div id="sweets" className="content-wrapper">
            <Helmet>
                <title>Sweets | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgCheesecake} />
                <meta property="og:url" content={imgCheesecake} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#D2730D", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Sweets</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sweets-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Cheesecake</td>
                                <td><img alt="Cheesecake" className="img-food-content" src={imgCheesecake} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Cupcake</td>
                                <td><img alt="Cupcake" className="img-food-content" src={imgCupcake} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Shortcake</td>
                                <td><img alt="Shortcake" className="img-food-content" src={imgShortcake} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Mom's Cherry Pie</td>
                                <td><img alt="Mom's Cherry Pie" className="img-food-content" src={imgCherryPie} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Choco-Banana</td>
                                <td><img alt="Choco-Banana" className="img-food-content" src={imgChocolateBanana} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Sweet Rice Cakes</td>
                                <td><img alt="Sweet Rice Cakes" className="img-food-content" src={imgSweetRiceCakes} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Royal Pancakes</td>
                                <td><img alt="Royal Pancakes" className="img-food-content" src={imgRoyalPancakes} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Jumbo Parfait</td>
                                <td><img alt="Jumbo Parfait" className="img-food-content" src={imgJumboParfait} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Spirit Doughnut</td>
                                <td><img alt="Spirit Doughnut" className="img-food-content" src={imgSpiritDoughnut} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Soul Doughnut</td>
                                <td><img alt="Soul Doughnut" className="img-food-content" src={imgSoulDoughnut} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Specter Doughnut</td>
                                <td><img alt="Specter Doughnut" className="img-food-content" src={imgSpecterDoughnut} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Sweets;