import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgChocobar from '../../assets/img/food/Chocobar.png';
import imgStrawberryChocobar from '../../assets/img/food/Strawberry_Chocobar.png';
import imgPremiumChocobar from '../../assets/img/food/Premium_Chocobar.png';

const Chocobars = props => {
    let navigate = useNavigate();

    return(
        <div id="chocobars" className="content-wrapper">
            <Helmet>
                <title>Chocobars | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgChocobar} />
                <meta property="og:url" content={imgChocobar} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#ED772D", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Chocobars</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="chocobars-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Chocobar</td>
                                <td><img alt="Chocobar" className="img-food-content" src={imgChocobar} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Strawberry Chocobar</td>
                                <td><img alt="Strawberry Chocobar" className="img-food-content" src={imgStrawberryChocobar} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Premium Chocobar</td>
                                <td><img alt="Premium Chocobar" className="img-food-content" src={imgPremiumChocobar} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Chocobars;