import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';

import '../../assets/css/Password.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import iconLogo1 from '../../assets/img/Yo-kai_Watch_logo.webp';
import iconLogo2 from '../../assets/img/Yo-kai_Watch_2_logo.webp';
import iconLogo3 from '../../assets/img/Yo-kai_Watch_3_logo.webp';
import ogImage from '../../assets/img/og-image.jpg';

const Password = props => {
    let navigate = useNavigate();

    return(
        <div id="password" className="content-wrapper">
            <Helmet>
                <title>Password | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:description" content="The site contains informations about all the yo-kais, their favorite foods, locations, items, evolutions, stats, etc." />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={ogImage} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#FF8041", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Password</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="password-content">
                <div className="container">
                    <div className="password-wrapper">
                        <div className="password">
                            <Link to={`/password-1`}><img alt="Yo-kai Watch 1" width="416" height="200" className="img-password-logo" src={iconLogo1} /></Link>
                        </div>
                        <div className="password">
                            <Link to={`/password-2`}><img alt="Yo-kai Watch 2" width="416" height="200" className="img-password-logo" src={iconLogo2} /></Link>
                        </div>
                        <div className="password">
                            <Link to={`/password-3`}><img alt="Yo-kai Watch 3" width="416" height="200" className="img-password-logo" src={iconLogo3} /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Password;