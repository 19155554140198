import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgSandwhich from '../../assets/img/food/Sandwhich.png';
import imgCandy from '../../assets/img/food/10-Cent_Gum.png';
import imgChineseFood from '../../assets/img/food/Pot_Stickers.png';
import imgChocobars from '../../assets/img/food/Chocobar.png';
import imgCurry from '../../assets/img/food/Chicken_Curry.png';
import imgDonuts from '../../assets/img/food/Donut.png';
import imgHamburgers from '../../assets/img/food/Hamburger.png';
import imgHotDogs from '../../assets/img/food/Plain_Hot_Dog.png';
import imgIceCream from '../../assets/img/food/Ice_Cream.png';
import imgJuice from '../../assets/img/food/Y-Cola.png';
import imgMeat from '../../assets/img/food/Chicken_Thigh.png';
import imgMegaTasty from '../../assets/img/food/Deliciousticks.png';
import imgMilk from '../../assets/img/food/Milk-0.png';
import imgOden from '../../assets/img/food/Steamed_Daikon.png';
import imgPasta from '../../assets/img/food/Bolognese.png';
import imgPizza from '../../assets/img/food/Hi-Cheese_Pizza.png';
import imgRamen from '../../assets/img/food/Ramen_Cup.png';
import imgRiceBalls from '../../assets/img/food/Plum_Rice_Ball.png';
import imgSeafood from '../../assets/img/food/Dried_Mackerel.png';
import imgSnacks from '../../assets/img/food/Potato_Chips.png';
import imgSoba from '../../assets/img/food/Kakesoba_Noodles.png';
import imgSukiyaki from '../../assets/img/food/Sukiyaki_(1).png';
import imgSushi from '../../assets/img/food/Cucumber_Roll.png';
import imgSweets from '../../assets/img/food/Cheesecake.png';
import imgTempura from '../../assets/img/food/Eggplant_Tempura.png';
import imgVeggies from '../../assets/img/food/Carrot-0.png';
import ogImage from '../../assets/img/og-image.jpg';

const Food = props => {
    let navigate = useNavigate();

    return(
        <div id="food" className="content-wrapper">
            <Helmet>
                <title>Food | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:description" content="The site contains informations about all the yo-kais, their favorite foods, locations, items, evolutions, stats, etc." />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={ogImage} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#FBA6CC", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Food</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="food-content">
                <div className="container">
                    <div className="food">
                        <Link style={{ backgroundColor: '#BC7231' }} className="img-food-wrapper" to={`/food/bread`}>
                            <img alt="Sandwhich" className="img-food" src={imgSandwhich} />
                            Bread
                        </Link>
                        <Link style={{ backgroundColor: '#4283A5' }} className="img-food-wrapper" to={`/food/candy`}>
                            <img alt="Candy" className="img-food" src={imgCandy} />
                            Candy
                        </Link>
                    </div>
                    <div className="food">
                        <Link style={{ backgroundColor: '#D62711' }} className="img-food-wrapper" to={`/food/chinese-food`}>
                            <img alt="Chinese Food" className="img-food" src={imgChineseFood} />
                            Chinese Food
                        </Link>
                        <Link style={{ backgroundColor: '#ED772D' }} className="img-food-wrapper" to={`/food/chocobars`}>
                            <img alt="Chocobars" className="img-food" src={imgChocobars} />
                            Chocobars
                        </Link>
                    </div>
                    <div className="food">
                        <Link style={{ backgroundColor: '#A05C30' }} className="img-food-wrapper" to={`/food/curry`}>
                            <img alt="Curry" className="img-food" src={imgCurry} />
                            Curry
                        </Link>
                        <Link style={{ backgroundColor: '#C2A18A' }} className="img-food-wrapper" to={`/food/donuts`}>
                            <img alt="Donuts" className="img-food" src={imgDonuts} />
                            Donuts
                        </Link>
                    </div>
                    <div className="food">
                        <Link style={{ backgroundColor: '#82AA2A' }} className="img-food-wrapper" to={`/food/hamburgers`}>
                            <img alt="Hamburgers" className="img-food" src={imgHamburgers} />
                            Hamburgers
                        </Link>
                        <Link style={{ backgroundColor: '#EEC75C' }} className="img-food-wrapper" to={`/food/hot-dogs`}>
                            <img alt="Hot Dogs" className="img-food" src={imgHotDogs} />
                            Hot Dogs
                        </Link>
                    </div>
                    <div className="food">
                        <Link style={{ backgroundColor: '#FEA543' }} className="img-food-wrapper" to={`/food/ice-cream`}>
                            <img alt="Ice Cream" className="img-food" src={imgIceCream} />
                            Ice Cream
                        </Link>
                        <Link style={{ backgroundColor: '#E8473A' }} className="img-food-wrapper" to={`/food/juice`}>
                            <img alt="Juice" className="img-food" src={imgJuice} />
                            Juice
                        </Link>
                    </div>
                    <div className="food">
                        <Link style={{ backgroundColor: '#EB6341' }} className="img-food-wrapper" to={`/food/meat`}>
                            <img alt="Meat" className="img-food" src={imgMeat} />
                            Meat
                        </Link>
                        <Link style={{ backgroundColor: '#FF982D' }} className="img-food-wrapper" to={`/food/mega-tasty`}>
                            <img alt="Mega Tasty" className="img-food" src={imgMegaTasty} />
                            Mega Tasty
                        </Link>
                    </div>
                    <div className="food">
                        <Link style={{ backgroundColor: '#d5b8a5' }} className="img-food-wrapper" to={`/food/milk`}>
                            <img alt="Milk" className="img-food" src={imgMilk} />
                            Milk
                        </Link>
                        <Link style={{ backgroundColor: '#F8CE5F' }} className="img-food-wrapper" to={`/food/oden`}>
                            <img alt="Oden" className="img-food" src={imgOden} />
                            Oden
                        </Link>
                    </div>
                    <div className="food">
                        <Link style={{ backgroundColor: '#F8CE5F' }} className="img-food-wrapper" to={`/food/pasta`}>
                            <img alt="Pasta" className="img-food" src={imgPasta} />
                            Pasta
                        </Link>
                        <Link style={{ backgroundColor: '#D45D13' }} className="img-food-wrapper" to={`/food/pizza`}>
                            <img alt="Pizza" className="img-food" src={imgPizza} />
                            Pizza
                        </Link>
                    </div>
                    <div className="food">
                        <Link style={{ backgroundColor: '#B76517' }} className="img-food-wrapper" to={`/food/ramen`}>
                            <img alt="Sandwhich" className="img-food" src={imgRamen} />
                            Ramen
                        </Link>
                        <Link style={{ backgroundColor: '#E7B48C' }} className="img-food-wrapper" to={`/food/rice-balls`}>
                            <img alt="Rice Balls" className="img-food" src={imgRiceBalls} />
                            Rice Balls
                        </Link>
                    </div>
                    <div className="food">
                        <Link style={{ backgroundColor: '#EDA38D' }} className="img-food-wrapper" to={`/food/seafood`}>
                            <img alt="Seafood" className="img-food" src={imgSeafood} />
                            Seafood
                        </Link>
                        <Link style={{ backgroundColor: '#97A642' }} className="img-food-wrapper" to={`/food/snacks`}>
                            <img alt="Snacks" className="img-food" src={imgSnacks} />
                            Snacks
                        </Link>
                    </div>
                    <div className="food">
                        <Link style={{ backgroundColor: '#8C6B4F' }} className="img-food-wrapper" to={`/food/soba`}>
                            <img alt="Soba" className="img-food" src={imgSoba} />
                            Soba
                        </Link>
                        <Link style={{ backgroundColor: '#E6835C' }} className="img-food-wrapper" to={`/food/sukiyaki`}>
                            <img alt="Sukiyaki" className="img-food" src={imgSukiyaki} />
                            Sukiyaki
                        </Link>
                    </div>
                    <div className="food">
                        <Link style={{ backgroundColor: '#6CB146' }} className="img-food-wrapper" to={`/food/sushi`}>
                            <img alt="Sushi" className="img-food" src={imgSushi} />
                            Sushi
                        </Link>
                        <Link style={{ backgroundColor: '#D2730D' }} className="img-food-wrapper" to={`/food/sweets`}>
                            <img alt="Sweets" className="img-food" src={imgSweets} />
                            Sweets
                        </Link>
                    </div>
                    <div className="food">
                        <Link style={{ backgroundColor: '#F8D24F' }} className="img-food-wrapper" to={`/food/tempura`}>
                            <img alt="Tempura" className="img-food" src={imgTempura} />
                            Tempura
                        </Link>
                        <Link style={{ backgroundColor: '#FF5111' }} className="img-food-wrapper" to={`/food/veggies`}>
                            <img alt="Veggies" className="img-food" src={imgVeggies} />
                            Veggies
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Food;