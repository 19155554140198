import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import '../../assets/css/NotFound.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgNotFound from '../../assets/img/img-not-found.webp';
import ogImage from '../../assets/img/og-image.jpg';

const NotFound = props => {
    let navigate = useNavigate();
    
    return(
        <div id="not-found" className="content-wrapper">
            <Helmet>
                <title>Page not found! | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:description" content="The site contains informations about all the yo-kais, their favorite foods, locations, items, evolutions, stats, etc." />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={ogImage} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#E85A3D", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Page not found!</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="not-found-content">
                <div className="container">
                    <div className="not-found">
                        <img alt="not found" className="img-not-found" src={imgNotFound} />
                        <p>Oops! It looks like this link is broken.</p>
                        <Link to={`/`}>Click here to return to the home page!</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound;