import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgEggplantTempura from '../../assets/img/food/Eggplant_Tempura.png';
import imgPumpkinTempura from '../../assets/img/food/Pumpkin_Tempura-0.png';
import imgChickenTempura from '../../assets/img/food/Chicken_Tempura.png';
import imgSmeltTempura from '../../assets/img/food/Smelt_Tempura-0.png';
import imgSquidTempura from '../../assets/img/food/Squid_Tempura-0.png';
import imgCongerEelTempura from '../../assets/img/food/Conger_Eel_Tempura.png';
import imgJumboShrimpTempura from '../../assets/img/food/Jumbo_Shrimp_Tempura-0.png';
import imgSuperbTempura from '../../assets/img/food/Superb_Tempura.png';
import imgSushiTempuraFeast from '../../assets/img/food/Sushi-Tempura_of_the_Imperial_Kitchen.png';
import imgTempuraSukiyakiFeast from '../../assets/img/food/Tempura-Sukiyaki_of_the_Imperial_Kitchen.png';

const Tempura = props => {
    let navigate = useNavigate();

    return(
        <div id="tempura" className="content-wrapper">
            <Helmet>
                <title>Tempura | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgEggplantTempura} />
                <meta property="og:url" content={imgEggplantTempura} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#F8D24F", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Tempura</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tempura-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Eggplant Tempura</td>
                                <td><img alt="Eggplant Tempura" className="img-food-content" src={imgEggplantTempura} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Pumpkin Tempura</td>
                                <td><img alt="Pumpkin Tempura" className="img-food-content" src={imgPumpkinTempura} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Chicken Tempura</td>
                                <td><img alt="Chicken Tempura" className="img-food-content" src={imgChickenTempura} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Fish Tempura</td>
                                <td><img alt="Fish Tempura" className="img-food-content" src={imgSmeltTempura} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Squid Tempura</td>
                                <td><img alt="Squid Tempura" className="img-food-content" src={imgSquidTempura} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Eel Tempura</td>
                                <td><img alt="Eel Tempura" className="img-food-content" src={imgCongerEelTempura} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Jumbo Shrimp Tempura</td>
                                <td><img alt="Jumbo Shrimp Tempura" className="img-food-content" src={imgJumboShrimpTempura} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Deluxe Tempura</td>
                                <td><img alt="Deluxe Tempura" className="img-food-content" src={imgSuperbTempura} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Sushi Tempura Feast</td>
                                <td><img alt="Sushi Tempura Feast" className="img-food-content" src={imgSushiTempuraFeast} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Tempura Sukiyaki Feast</td>
                                <td><img alt="Tempura Sukiyaki Feast" className="img-food-content" src={imgTempuraSukiyakiFeast} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Tempura;