import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import img10CentGum from '../../assets/img/food/10-Cent_Gum.png';
import imgGooeyCandy from '../../assets/img/food/Gooey_Candy.png';
import imgGiantCracker from '../../assets/img/food/Giant_Cracker.png';
import imgFruitDrops from '../../assets/img/food/Fruit_Drops.png';
import imgShavedIce from '../../assets/img/food/Shaved_Ice.png';
import imgRainbowJellyBeans from '../../assets/img/food/Rainbow_Jelly_Beans.png';
import imgCandyApple from '../../assets/img/food/Candy_Apple.png';

const Candy = props => {
    let navigate = useNavigate();

    return(
        <div id="candy" className="content-wrapper">
            <Helmet>
                <title>Candy | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={img10CentGum} />
                <meta property="og:url" content={img10CentGum} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#4283A5", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Candy</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="candy-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>10-Cent Gum</td>
                                <td><img alt="10-Cent Gum" className="img-food-content" src={img10CentGum} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Gooey Candy</td>
                                <td><img alt="Gooey Candy" className="img-food-content" src={imgGooeyCandy} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Giant Cracker</td>
                                <td><img alt="Giant Cracker" className="img-food-content" src={imgGiantCracker} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Fruit Drops</td>
                                <td><img alt="Fruit Drops" className="img-food-content" src={imgFruitDrops} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Shaved Ice</td>
                                <td><img alt="Rainbow Jelly Beans" className="img-food-content" src={imgShavedIce} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Rainbow Jelly Beans</td>
                                <td><img alt="Candy Apple" className="img-food-content" src={imgRainbowJellyBeans} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Candy Apple</td>
                                <td><img alt="Candy Apple" className="img-food-content" src={imgCandyApple} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Candy;