import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgCucumberRoll from '../../assets/img/food/Cucumber_Roll.png';
import imgShrimpSushi from '../../assets/img/food/Shrimp_Sushi-0.png';
import imgCaliforniaRoll from '../../assets/img/food/California_Roll-0.png';
import imgEggSushi from '../../assets/img/food/Egg_Sushi-0.png';
import imgRoeMaki from '../../assets/img/food/Roe_Maki.png';
import imgSalmonRoeSushi from '../../assets/img/food/Salmon_Roe_Sushi.png';
import imgFlounderSushi from '../../assets/img/food/Flounder_Sushi.png';
import imgUrchinSushi from '../../assets/img/food/Urchin_Sushi.png';
import imgFattyTunaSushi from '../../assets/img/food/Fatty_Tuna_Sushi-0.png';
import imgFirstRateSushi from '../../assets/img/food/First_Rate_Sushi.png';
import imgSushiTempuraFeast from '../../assets/img/food/Sushi-Tempura_of_the_Imperial_Kitchen.png';
import imgSushiSukiyakiFeast from '../../assets/img/food/Sushi-Sukiyaki_of_the_Imperial_Kitchen.png';

const Sushi = props => {
    let navigate = useNavigate();

    return(
        <div id="sushi" className="content-wrapper">
            <Helmet>
                <title>Sushi | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgCucumberRoll} />
                <meta property="og:url" content={imgCucumberRoll} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#6CB146", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Sushi</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sushi-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Cucumber Roll</td>
                                <td><img alt="Cucumber Roll" className="img-food-content" src={imgCucumberRoll} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Shrimp Sushi</td>
                                <td><img alt="Shrimp Sushi" className="img-food-content" src={imgShrimpSushi} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>California Rolls</td>
                                <td><img alt="California Rolls" className="img-food-content" src={imgCaliforniaRoll} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Egg Sushi</td>
                                <td><img alt="Egg Sushi" className="img-food-content" src={imgEggSushi} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Salmon Roe Sushi</td>
                                <td><img alt="Salmon Roe Sushi" className="img-food-content" src={imgRoeMaki} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Salmon Sushi</td>
                                <td><img alt="Salmon Sushi" className="img-food-content" src={imgSalmonRoeSushi} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Fin Sushi</td>
                                <td><img alt="Fin Sushi" className="img-food-content" src={imgFlounderSushi} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Sea Urchin Sushi</td>
                                <td><img alt="Sea Urchin Sushi" className="img-food-content" src={imgUrchinSushi} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Fatty Tuna Sushi</td>
                                <td><img alt="Fatty Tuna Sushi" className="img-food-content" src={imgFattyTunaSushi} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>High-End Sushi</td>
                                <td><img alt="High-End Sushi" className="img-food-content" src={imgFirstRateSushi} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Sushi Tempura Feast</td>
                                <td><img alt="Sushi Tempura Feast" className="img-food-content" src={imgSushiTempuraFeast} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Sushi Sukiyaki Feast</td>
                                <td><img alt="Sushi Sukiyaki Feast" className="img-food-content" src={imgSushiSukiyakiFeast} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Sushi;