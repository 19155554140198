import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgSteamedDaikon from '../../assets/img/food/Steamed_Daikon.png';
import imgBoiledEgg from '../../assets/img/food/Boiled_Egg.png';
import imgBeefSkewers from '../../assets/img/food/Beef_Skewers.png';
import imgTopClassOden from '../../assets/img/food/Top-Class_Oden.png';

const Oden = props => {
    let navigate = useNavigate();

    return(
        <div id="oden" className="content-wrapper">
            <Helmet>
                <title>Oden | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgSteamedDaikon} />
                <meta property="og:url" content={imgSteamedDaikon} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#F8CE5F", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Oden</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="oden-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Steamed Daikon</td>
                                <td><img alt="Steamed Daikon" className="img-food-content" src={imgSteamedDaikon} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Boiled Egg</td>
                                <td><img alt="Boiled Egg" className="img-food-content" src={imgBoiledEgg} /></td>
                                <td>Recovers a bit of HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Beef Skewers</td>
                                <td><img alt="Beef Skewers" className="img-food-content" src={imgBeefSkewers} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Top-Class Oden</td>
                                <td><img alt="Top-Class Oden" className="img-food-content" src={imgTopClassOden} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Oden;