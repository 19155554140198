import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgPotStickers from '../../assets/img/food/Pot_Stickers.png';
import imgLiverChives from '../../assets/img/food/Liver_Chives.png';
import imgCrabOmelet from '../../assets/img/food/Crab_Omelet.png';
import imgChiliShrimp from '../../assets/img/food/Chili_Shrimp.png';
import imgMapoTofu from '../../assets/img/food/Mapo_Tofu.png';

const ChineseFood = props => {
    let navigate = useNavigate();

    return(
        <div id="chinese-food" className="content-wrapper">
            <Helmet>
                <title>Chinese Food | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgPotStickers} />
                <meta property="og:url" content={imgPotStickers} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#D62711", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Chinese Food</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="chinese-food-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Pot Stickers</td>
                                <td><img alt="Pot Stickers" className="img-food-content" src={imgPotStickers} /></td>
                                <td>Recovers some HP</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Liver & Chives</td>
                                <td><img alt="Liver & Chives" className="img-food-content" src={imgLiverChives} /></td>
                                <td>Recovers a chunk of HP</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Crab Omelet</td>
                                <td><img alt="Crab Omelet" className="img-food-content" src={imgCrabOmelet} /></td>
                                <td>Recovers a lot of HP</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>Chili Shrimp</td>
                                <td><img alt="Chili Shrimp" className="img-food-content" src={imgChiliShrimp} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                            <tr>
                                <td>Mapo Tofu</td>
                                <td><img alt="Mapo Tofu" className="img-food-content" src={imgMapoTofu} /></td>
                                <td>Recovers a ton of HP</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ChineseFood;