import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Food.css';
import iconBack from '../../assets/img/menu/icon-back.svg';
import iconNavbar from '../../assets/img/menu/icon-navbar.svg';
import imgYCola from '../../assets/img/food/Y-Cola.png';
import imgSoulTea from '../../assets/img/food/Soul_Tea_2.png';
import imgSpiritizerY from '../../assets/img/food/Spiritizer_Y_2.png';
import imgDoctorRapper from '../../assets/img/food/Doctor_Rapper.png';
import imgYColaMax from '../../assets/img/food/Y-Cola_2_Liter.png';
import imgVoltXtreme from '../../assets/img/food/VoltXtreme.png';

const Juice = props => {
    let navigate = useNavigate();

    return(
        <div id="juice" className="content-wrapper">
            <Helmet>
                <title>Juice | YokaiDex Where you can find all information from Yokai-Watch games!</title>
                <meta property="og:image" content={imgYCola} />
                <meta property="og:url" content={imgYCola} />
            </Helmet>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#E8473A", }}>
                        <div className="header-title-wrapper">
                            <span onClick={() => navigate(-1)} className="menu-back"><img alt="back" className="icon-back" src={iconBack} /></span>
                            <h1 className="header-title">Juice</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="juice-content">
                <div className="container">
                    <table cellSpacing={0} className="table-food">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>On Friend</th>
                                <th>On Foe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Y-Cola</td>
                                <td><img alt="Y-Cola" className="img-food-content" src={imgYCola} /></td>
                                <td>Adds a bit to the Soul Meter</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Soul Tea</td>
                                <td><img alt="Soul Tea" className="img-food-content" src={imgSoulTea} /></td>
                                <td>Adds a bit to the Soul Meter</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Spiritizer Y</td>
                                <td><img alt="Spiritizer Y" className="img-food-content" src={imgSpiritizerY} /></td>
                                <td>Adds a bit to the Soul Meter</td>
                                <td>Yo-kai will like you a bit</td>
                            </tr>
                            <tr>
                                <td>Doctor Rapper</td>
                                <td><img alt="Doctor Rapper" className="img-food-content" src={imgDoctorRapper} /></td>
                                <td>Adds a chunk to the Soul Meter</td>
                                <td>Yo-kai will like you some</td>
                            </tr>
                            <tr>
                                <td>Y-Cola Max</td>
                                <td><img alt="Y-Cola Max" className="img-food-content" src={imgYColaMax} /></td>
                                <td>Adds a lot to the Soul Meter</td>
                                <td>Yo-kai will like you a lot</td>
                            </tr>
                            <tr>
                                <td>VoltXtreme</td>
                                <td><img alt="VoltXtreme" className="img-food-content" src={imgVoltXtreme} /></td>
                                <td>Almost fills up the Soul Meter</td>
                                <td>Yo-kai will like you a ton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Juice;